<template>
  <div class="home">
    <el-container>
      <el-header style="height: 200px;width: 800px;display: flex;align-items: center;">
        <div
            style="height: 200px;width: 800px;display: flex;flex-flow:column;justify-content:center;lllllllllsssssalign-items: center;">
          <div class="check-source">
            <div :class="collection==='Periodical'?'check-active':'check-no-active'"
                 @click="checkSource('Periodical')">文章
            </div>
            <div :class="collection==='Magazine'?'check-active':'check-no-active'"
                 @click="checkSource('Magazine')">期刊
            </div>
          </div>
          <div class="search-input">
            <el-input @change="toSearch" placeholder="请输入关键词" v-model="word" class="input-with-select">
              <el-select v-show="collection==='Periodical'" v-model="select" slot="prepend">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
                <el-option label="作者" value="作者"></el-option>
                <el-option label="刊名" value="刊名"></el-option>
                <el-option label="作者单位" value="作者单位"></el-option>
                <el-option label="关键词" value="关键词"></el-option>
              </el-select>
              <el-select v-show="collection==='Magazine'" v-model="select" slot="prepend">
                <el-option label="所有领域" value="所有领域"></el-option>
                <el-option label="题名" value="题名"></el-option>
              </el-select>
              <i slot="suffix" v-if="word" @click="clearWord">
                <img src="../assets/clear.png" alt="">
              </i>
              <el-button slot="append" class="btn-search" @click="toSearch('new')">
                搜索
              </el-button>
            </el-input>
          </div>
          <div
              style="margin-top: 17px;display: flex;font-size: 14px;font-family: Source Han Sans CN;
              font-weight: 400;color: #666666;width: 800px;flex-wrap: nowrap;"
              v-if="absData&&absData.length&&absData.length>0&&absData[0].values&&absData[0].values.length>0">
            <div style="width: 100px;display: flex;align-items: center;">相关检索词：</div>
            <div class="onClickSearch" style="margin-right: 15px;cursor: pointer;display: flex;flex-wrap: nowrap;
                  white-space: nowrap;align-items: center;overflow: hidden;text-overflow: ellipsis"
                 :style="(absData[0].values||[]).length-1=== index?'flex: 1;':''"
                 v-for="(item,index) in absData[0].values||[]">
              <span :title="item.name" @click="onClickSearch(item.name)">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </el-header>
      <el-skeleton
          :rows="20"
          :style="'width:100%;min-height: 600px;'"
          :loading="loading"
          animated
          :throttle="500"
      >
        <el-main style="display: flex;flex-flow: column;align-items: center" v-show="totalCount>0">
          <div
              style="width: 1200px;min-height: 60px;display: flex;align-items: flex-end;justify-content: space-between;">
            <div style="width: 300px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              共检索出文章
              {{ totalCount }}
            </div>
            <div id="facet-select" style="width: 650px;display: flex;white-space: nowrap;overflow: hidden;">
              <div v-for="(item,index) in filter" style="height: 24px;background: #F5F5F5;margin-right: 5px;">
                <span
                    style="padding: 0 10px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">{{
                    convert(item)
                  }}</span>
                <img @click="deleteFact(index)" style="cursor:pointer;padding: 0 10px" width="10" height="9"
                     src="../assets/clear-fact.png" alt="">
              </div>
            </div>
            <div style="width: 250px;display: flex;justify-content: flex-end;">
              <div style="display: flex;cursor: pointer" v-show="filter.length&&filter.length>0&&showSelect">
                <img @click="right" src="../assets/left.png" alt=""/>
                <img @click="left" style="margin-left:5px;" src="../assets/right.png" alt=""/>
                <div @click="clearSelect"
                     style="margin-left:5px;display:flex;align-items:center;justify-content:center;width: 40px;height: 24px;background: #F5F5F5;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;">
                  清除
                </div>
              </div>
            </div>
          </div>
          <div style="width: 1200px;height: 1px;background: #DDDDDD;margin-top: 10px;margin-bottom: 20px"></div>
          <div style="display: flex;">
            <div style="width:280px" class="aside">
              <el-aside width="280px" style="background-color: rgb(238, 241, 246)">
                <el-menu
                    :default-openeds="['0','1','2','3','4','5']"
                    class="el-menu-vertical-demo">
                  <el-checkbox-group v-model="filter" @change="handleCheckedFactChange">
                    <el-submenu
                        v-show="(item.values||[]).length!==0"
                        v-for="(item,index) in (trans?facetFieldDataZh:facetFieldData)" :index="index+''">
                      <template slot="title" style="padding-left: 0">
                      <span
                          style="width: 28px;height: 15px;font-size: 14px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;line-height: 24px;">{{
                          item.name.replace("_Translated", "")
                        }}</span>
                      </template>
                      <span @click.stop.prevent="toDocument">
                        <el-tree v-if="index===1"
                                 ref="tree"
                                 :default-expanded-keys="filterNode"
                                 :data="facetFieldDataClassCode"
                                 node-key="id">
                          <span class="custom-tree-node" slot-scope="{ node, data }">
                            <el-checkbox style="display: flex;"
                                         :label='item.name+":"+node.label'>
                              <div :title="convert(node.label)" @click.stop.prevent="toDocument"
                                   style="width: 185px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap">{{
                                  convert(node.label)
                                }}</div>
                            </el-checkbox>
                          </span>
                        </el-tree>
                      </span>
                      <el-menu-item v-if="index!==1" class="facet-item" v-for="(val,i) in item.values||[]"
                                    :index="(index+'-'+i)">
                        <el-checkbox style="display: flex;"
                                     :label='item.name==="年份"?item.name+":"+val.name:item.name.replace("_Translated","")+":\""+val.name+"\""'>
                          <div style="width: 210px;display: flex;justify-content: space-between">
                            <div style="width: 180px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"
                                 :title="val.name">{{
                                !trans ?
                                    typeof (val.name) === "string" ? classCode[val.name.trim()] || language[val.name.trim()] || val.name : val.name
                                    : typeof (val.name) === "string" ? classCodeZh[val.name.trim()] || languageZh[val.name.trim()] || val.name : val.name
                              }}
                            </div>
                            <div>{{ val.count }}</div>
                          </div>
                        </el-checkbox>
                      </el-menu-item>
                      <el-menu-item
                          v-show="item.hasMore===undefined||item.hasMore"
                          v-if="index!==1&&item.values&&item.values.length&&item.values.length!==0&&item.values.length%pageSizeFacet===0"
                          class="facet-item" :index="item.name+'1'">
                      <span style="color:#231815 ;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;"
                            @click="toMore(item,item.name,item.values.length)">更多</span>
                      </el-menu-item>
                    </el-submenu>
                  </el-checkbox-group>
                </el-menu>
              </el-aside>
            </div>
            <div style="width: 920px" class="main-content">
              <div style="display: flex;align-items: center;margin-top: 10px;margin-bottom: 23px; ">
                <div
                    style="cursor: pointer;margin-left: 35px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;
                    color: #231815;"
                    @click="handleCheckedAllChange(!isCheck)">全选
                </div>
                <div @click="batchTransDoc"
                     :style="checkList&&checkList.length===0?'color: #999999!important;cursor:not-allowed':''"
                     style="cursor: pointer;margin-left:15px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;">
                  批量翻译
                </div>
                <div
                    style="width: 100px;margin-left:25px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  已选<span style="color:rgba(245, 108, 108, 1)">{{ checkList.length || 0 }}</span>个项目
                </div>
                <div style="width: 30px;display: flex;justify-content: center;">
                  <img v-show="checkList&&checkList.length>0" src="../assets/clear.png" alt=""
                       @click="clearCheck"/>
                </div>
                <div
                    style="cursor: pointer;display: flex;align-items: center;justify-content: center;margin-left: 460px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                  排序：<span
                    style="color: #666666;cursor: pointer">{{ '相关度' }}</span>
                  <span style="padding-left: 20px;color: #666666;cursor: pointer">
                    {{ '出版年' }}</span>
                  <div>
                    <div v-show="sortOrder===3"
                         style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                      <img @click="sort(1)"
                           src="../assets/default_up.png" alt=""/>
                      <img @click="sort(0)"
                           src="../assets/default_down.png" alt=""/>
                    </div>
                    <div v-show="sortOrder===0"
                         style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                      <img @click="sort(1)"
                           src="../assets/default_up.png" alt=""/>
                      <img @click="sort(3)"
                           src="../assets/dw.png" alt=""/>
                    </div>
                    <div v-show="sortOrder===1"
                         style="margin-left: 8px;display: flex;flex-flow: column;height: 14px;justify-content: space-between;align-items: center;">
                      <img @click="sort(3)"
                           src="../assets/dw.png" alt="" style="transform: rotateX(180deg)"/>
                      <img @click="sort(0)"
                           src="../assets/default_down.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
                <div v-for="(item,index) in data" :key="item.id">
                  <el-card class="box-card">
                    <el-checkbox style="display: -webkit-box;" :label="JSON.stringify(item)"
                                 :disabled="item.Translated_Status&&item.Translated_Status==='ok'">
                      <div v-show="!trans" class="text">
                        <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id,
                        trans:trans
                      }}">
                          <div style="display: flex;margin-bottom: 10px;margin-top: 3px;">
                            <div
                                style="width: 740px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 30px;margin-top: -7px;"
                                v-html="item.Title"></div>
                            <div v-show="item.Translated_Status&&item.Translated_Status==='ok'">
                              <img src="../assets/yi.png" alt="" style="margin-left: 10px;"/>
                            </div>
                          </div>
                        </router-link>
                        <div v-if="item.Creator"
                             style="color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          <span v-for="(name, index) in item.Creator?item.Creator.split('%%'):[]"
                                :key="index">
                            <span @click="toOther('作者',name)" v-html="name"></span>
                            <span v-if="item.Creator&&index!==item.Creator.split('%%').length-1">&nbsp;&nbsp;</span>
                          </span>
                          <span style="margin-left: 35px;" v-if="item.PublishDate">{{ item.PublishDate }}</span>
                        </div>
                        <div v-if="item.DOI"
                             style="margin-top:10px;color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;"
                             @click.stop.prevent="toDocument">
                          <span>DOI:</span>
                          <span style="margin-left: 10px;">
                          {{ item.DOI }}
                          </span>
                        </div>
                        <div v-if="item.ForeignKeywords"
                             style="margin-top:17px;color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          <span>Article keywords:</span>
                          <span v-for="(name, index) in item.ForeignKeywords?item.ForeignKeywords.split(';'):[]"
                                :key="index">
                            <span @click="toOther('关键词',name)" v-html="name"></span>
                            <span v-if="item.ForeignKeywords&&index!==item.ForeignKeywords.split(';').length-1">&nbsp;&nbsp;</span>
                          </span>
                        </div>
                        <div style="color: #606266;" @click.stop.prevent="toDocument" v-show="item.Abstract">
                          <el-menu class="el-menu-vertical-demo">
                            <el-submenu index="1">
                              <template slot="title">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 40px;">Abstract</span>
                              </template>
                              <span
                                  style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  v-html="item.Abstract"></span>
                            </el-submenu>
                          </el-menu>
                        </div>
                        <div v-show="item.HasFulltext.toLowerCase()==='y'"
                             style="cursor:pointer;display: flex;justify-content: flex-start;width: 100%;font-size: 14px;
                             font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 40px;"
                             @click.stop.prevent="toDocument">
                          <div @click="readView(item)" style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/read-active.png" alt=""/>
                            阅读
                          </div>
                          <el-dropdown v-show="checkRemarkDoc[item.id]" @command="handleOrgCommand($event,item)">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                                display: flex;align-items: center;width: 78px;
                                font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              <img src="../assets/down-active.png" alt=""/>
                              下载
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="org">
                                <el-radio v-model="clas" label="org">原文</el-radio>
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="tran">
                                <el-radio v-model="clas" label="tran">译文</el-radio>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <el-popover v-show="!checkRemarkDoc[item.id]"
                                      placement="bottom"
                                      width="239"
                                      trigger="hover">
                            <div
                                style="width: 100%;display:flex;justify-content: center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              请阅读详情并
                              <router-link target="_blank" :to="{name:'Document',query: {
                                collection: 'Periodical',
                                id: item.id,
                                trans:trans
                              }}"><span style="cursor: pointer;color: #A2CCCA;padding: 0 10px">进行评价</span>
                              </router-link>
                              即可下载
                            </div>
                            <div slot="reference"
                                 style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                                display: flex;align-items: center;width: 78px;
                                font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              <img src="../assets/down-detail.png" alt=""/>
                              下载
                            </div>
                          </el-popover>
                          <div @click="transDoc(item)" v-show="!item.Translated_Status||item.Translated_Status!=='ok'"
                               style="display: flex;align-items: center;margin-right: 14px;
                               font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/trans-active.png" alt=""/>
                            翻译
                          </div>
                          <div style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/share-active.png" alt=""/>
                            <el-popover
                                placement="bottom"
                                width="140"
                                trigger="hover">
                              <div
                                  style="height: 140px;display: flex;flex-flow: column;justify-content: space-between;">
                                <div style="display: flex;align-items: center;"
                                     @click="shareTo('wechat',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/wechar.png" width="24" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    微信
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;"
                                     @click="shareTo('sina',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/webo.png" width="23" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    微博
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;"
                                     @click="shareTo('qq',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/qq.png" width="20" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    QQ
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;"
                                     @click="shareTo('qzone',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/qzone.png" width="24" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    QQ空间
                                  </div>
                                </div>

                              </div>
                              <el-button type="text" slot="reference">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                转发
                              </span>
                              </el-button>
                            </el-popover>
                          </div>
                          <div @click="collDoc(item,index)" style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img v-show="!docIds[item.id]" src="../assets/collection-active.png" alt=""/>
                            <img v-show="docIds[item.id]" src="../assets/collection-ok.png" alt=""/>
                            <el-button type="text" :loading="collDocLoading===index">
                               <span
                                   style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                收藏
                              </span>
                            </el-button>
                          </div>
                        </div>
                      </div>
                      <div v-show="trans" class="text">
                        <router-link target="_blank" :to="{name:'Document',query: {
                        collection: 'Periodical',
                        id: item.id,
                        trans:trans
                      }}">
                          <div style="display: flex;margin-bottom: 10px;margin-top: 3px;">
                            <div
                                style="width: 740px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 30px;margin-top: -7px;"
                                v-html="item.Title_Translated"></div>
                            <div v-show="item.Translated_Status&&item.Translated_Status==='ok'">
                              <img src="../assets/yi.png" alt="" style="margin-left: 10px;"/>
                            </div>
                          </div>
                        </router-link>
                        <div v-if="item.Creator_Translated"
                             style="color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          <span v-for="(name, index) in item.Creator_Translated?item.Creator_Translated.split('%%'):[]"
                                :key="index">
                            <span @click="toOther('作者',name)" v-html="name"></span>
                            <span
                                v-if="item.Creator_Translated&&index!==item.Creator_Translated.split('%%').length-1">&nbsp;&nbsp;</span>
                          </span>
                          <span style="margin-left: 35px;"
                                v-if="item.PublishDate_Translated">{{ item.PublishDate_Translated }}</span>
                        </div>
                        <div v-if="item.DOI"
                             style="margin-top:10px;color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;"
                             @click.stop.prevent="toDocument">
                          <span>DOI:</span>
                          <span style="margin-left: 10px;">
                          {{ item.DOI }}
                          </span>
                        </div>
                        <div v-if="item.ForeignKeywords_Translated"
                             style="margin-top:17px;color: #606266;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;line-height: 30px;"
                             @click.stop.prevent="toDocument">
                          <span>关键词:</span>
                          <span
                              v-for="(name, index) in item.ForeignKeywords_Translated?item.ForeignKeywords_Translated.split(';'):[]"
                              :key="index">
                            <span @click="toOther('关键词',name)" v-html="name"></span>
                            <span
                                v-if="item.ForeignKeywords_Translated&&index!==item.ForeignKeywords_Translated.split(';').length-1">&nbsp;&nbsp;</span>
                          </span>
                        </div>
                        <div style="color: #606266;" @click.stop.prevent="toDocument" v-show="item.Abstract_Translated">
                          <el-menu class="el-menu-vertical-demo">
                            <el-submenu index="1">
                              <template slot="title">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 40px;">摘要</span>
                              </template>
                              <span
                                  style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  v-html="item.Abstract_Translated"></span>
                            </el-submenu>
                          </el-menu>
                        </div>
                        <div v-show="item.HasFulltext.toLowerCase()==='y'" class="down-button"
                             style="cursor:pointer;display: flex;justify-content: flex-start;width: 100%;font-size: 14px;
                             font-family: Source Han Sans CN;font-weight: 400;color: #231815;line-height: 40px;"
                             @click.stop.prevent="toDocument">
                          <div @click="readView(item)" style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/read-active.png" alt=""/>
                            阅读
                          </div>
                          <el-dropdown v-show="checkRemarkDoc[item.id]" @command="handleOrgCommand($event,item)">
                            <div
                                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                                display: flex;align-items: center;width: 78px;
                                font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              <img src="../assets/down-active.png" alt=""/>
                              下载
                            </div>
                            <el-dropdown-menu slot="dropdown"
                                              style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="org">
                                <el-radio v-model="clas" label="org">原文</el-radio>
                              </el-dropdown-item>
                              <el-dropdown-item
                                  style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                                  command="tran">
                                <el-radio v-model="clas" label="tran">译文</el-radio>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                          <el-popover v-show="!checkRemarkDoc[item.id]"
                                      placement="bottom"
                                      width="239"
                                      trigger="hover">
                            <div
                                style="width: 100%;display:flex;justify-content: center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              请阅读详情并
                              <router-link target="_blank" :to="{name:'Document',query: {
                                collection: 'Periodical',
                                id: item.id,
                                trans:trans
                              }}"><span style="cursor: pointer;color: #A2CCCA;padding: 0 10px">进行评价</span>
                              </router-link>
                              即可下载
                            </div>
                            <div slot="reference"
                                 style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                                display: flex;align-items: center;width: 78px;
                                font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                              <img src="../assets/down-detail.png" alt=""/>
                              下载
                            </div>
                          </el-popover>
                          <div @click="transDoc(item)" v-show="!item.Translated_Status||item.Translated_Status!=='ok'"
                               style="display: flex;align-items: center;margin-right: 14px;
                               font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/trans-active.png" alt=""/>
                            翻译
                          </div>
                          <div style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img src="../assets/share-active.png" alt=""/>
                            <el-popover
                                placement="bottom"
                                width="140"
                                trigger="hover">
                              <div
                                  style="height: 140px;display: flex;flex-flow: column;justify-content: space-between;">
                                <div style="display: flex;align-items: center;cursor: pointer"
                                     @click="shareTo('wechat',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/wechar.png" width="24" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    微信
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer"
                                     @click="shareTo('sina',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/webo.png" width="23" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    微博
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer"
                                     @click="shareTo('qq',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/qq.png" width="20" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    QQ
                                  </div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer"
                                     @click="shareTo('qzone',item.Title_Translated,item.Abstract_Translated,item.id)">
                                  <img src="../assets/qzone.png" width="24" alt="" style="margin-left: 3px"/>
                                  <div
                                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                                    QQ空间
                                  </div>
                                </div>

                              </div>
                              <el-button type="text" slot="reference">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                转发
                              </span>
                              </el-button>
                            </el-popover>
                          </div>
                          <div @click="collDoc(item,index)" style="display: flex;align-items: center;margin-right: 14px;
                          font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                            <img v-show="!docIds[item.id]" src="../assets/collection-active.png" alt=""/>
                            <img v-show="docIds[item.id]" src="../assets/collection-ok.png" alt=""/>
                            <el-button type="text" :loading="collDocLoading===index">
                              <span
                                  style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                收藏
                              </span>
                            </el-button>
                          </div>
                        </div>
                      </div>
                    </el-checkbox>
                    <div style="display: flex;align-items: end;margin-top: 10px;width: 100%;height: 45px;
                        border-top: 1px solid #DDDDDD;font-size: 14px;
                        font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                      <div style="width: 70px;height: 29px;display: flex;justify-content: end;align-items: center;
                      margin-right: 10px">评价
                      </div>
                      <div v-show="!remarkMap[item.id]" style="width: 81px;height: 29px;background: #F5F5F5;
                      display: flex;align-items: center;justify-content: center;">暂无评价
                      </div>
                      <div v-show="remarkMap[item.id]" v-for="(remark,inde) in remarkMap[item.id]||[]"
                           :class="remark.check?'check-remark':''" :key="inde+'_remark'"
                           style="padding: 0 12px;height: 29px;background: #F5F5F5;margin-right: 4px;
                      display: flex;align-items: center;justify-content: center;">
                        {{ remark.remarkName }} <span style="margin-right: 5px"></span>{{ formatCount(remark.count) }}
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-checkbox-group>
            </div>
          </div>
          <div class="pages"
               style="width: 1200px;display: flex;justify-content: flex-end;margin-top: 20px;margin-bottom: 40px">
            <el-pagination
                @prev-click="toPageChange(1)"
                @next-click="toPageChange(totalCount/pageSize)"
                prev-text="第一页"
                next-text="最后一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="totalCount">
            </el-pagination>
          </div>
        </el-main>
        <Empty v-bind:keyList="absData" v-bind:word="word" v-show="!(totalCount>0)"/>
      </el-skeleton>
    </el-container>
    <div v-if="isShowTransDoc" style="
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 140px;
          right: 0;
          border-radius: 6px;">
      <div class="isShowTransDoc" v-for="(item,index) in JSON.parse(isShowTransDoc)" @click="">
        <div
            style="color: #333333 !important;padding: 14px;">
          <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
            <div style="width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                 v-html="item.Title"></div>
            <img style="cursor: pointer" src="../assets/close.png" alt="" @click="closes(item.id)"/>
          </div>
          <el-progress :percentage="item.number?item.number:0" :format="format">
          </el-progress>
        </div>
      </div>
    </div>
    <div v-if="downLoadList&&downLoadList.length>0" class="downs" style="
    height: 50px;
          z-index: 100;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 10px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <el-progress v-for="(item,index) in  downLoadList" :key="item.id" style="padding-left: 10px;padding-right: 10px"
                     :stroke-width="2" :color="'rgb(6, 63, 149)'"
                     status="success" :width="35" type="circle"
                     :percentage="50">
        </el-progress>
      </div>
    </div>
    <div v-if="isOk" style="
          height: 50px;
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 75px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;width: 100%;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <span>翻译完成<span style="cursor: pointer;color: rgba(95, 138, 202, 1);margin-right: 22px"
                        @click="toList">去查看</span></span>
        <img src="../assets/OK.png" alt=""/>
      </div>
    </div>
    <el-dialog
        class="dialog"
        :title="downLoadItem?downLoadItem.Title||'':''"
        :visible.sync="centerDialogVisible"
        width="641px">
      <div style="width: 479px;">
        <div>
          <span style="margin-right: 10px">{{
              downLoadItem ? downLoadItem.PeriodicalTitle ? downLoadItem.PeriodicalTitle : '' : ''
            }}</span>
          {{ downLoadItem ? downLoadItem.PublishDate ? '(' + downLoadItem.PublishDate + ')' : '' : '' }}
        </div>
        <div style="margin-top: 50px">请选择下载格式：</div>
        <div style="margin-top: 20px" class="checks">
          <el-radio-group v-model="radio">
            <el-radio :label="'pdf'">PDF</el-radio>
            <el-radio :label="'docx'">WORD</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div slot="footer" style="width: 100%;display: flex;justify-content: center;margin-bottom: 58px;margin-top: 84px">
        <div
            style="cursor: pointer;display: flex;align-items: center;justify-content: center;width: 255px;height: 54px;background: #231815;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;"
            @click="downloadDoc">{{ clas === 'org' ? '确定下载原文' : '确定下载译文' }}
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :top="'40vh'"
        :show-close="false"
        :visible.sync="setMailVisible"
        width="280px"
        center>
        <span slot="title"
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
           您暂未设置邮箱，请填写邮箱
        </span>
      <div style="width: 100%;display: flex;justify-content: center">
        <div
            style="width: 95px;height: 30px;background: #231815;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
            <span style="cursor: pointer;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;"
                  @click="toSetMail">
              填写邮箱
            </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="分享到微信朋友圈"
        :visible.sync="qrcodeCenterDialogVisible"
        width="358px">
      <div
          style="margin-top: -25px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #e8eaec;">
        <vue-qr :size="220" :text="commonUrl"></vue-qr>
      </div>
      <div style="text-align: left;border-top: 1px solid #e8eaec;padding-top: 16px">
        打开微信，使用 “扫一扫”即可将网页分享到我的朋友圈
      </div>
    </el-dialog>
    <el-dialog
        title="分享到QQ"
        :visible.sync="qqQrcodeCenterDialogVisible"
        width="550px">
      <div
          style="width: 500px;height:550px;margin-top: -25px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #e8eaec;">
        <iframe scrolling="no" id="iframe" :src="iframeSrc" style="height:500px;" width="100%" frameborder="0"></iframe>
      </div>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="downCenterDialogVisible"
        width="486px"
        :show-close="false"
        :before-close="clearClas"
        center>
      <div slot="title" style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
        请选择下载格式
      </div>
      <div style="width: 100%;display:flex;justify-content: center;align-items: center">
        <div class="option" :style="clas!=='tran'?'width: 50%;':'width: 100%;'"
             style="cursor: pointer;margin: -30px 35px;display: flex;align-items: center;justify-content: space-between;">
          <div :class="comment==='pdf'?'select':''" @click="checkComment('pdf')">PDF
          </div>
          <div :class="comment==='docx'?'select':''" @click="checkComment('docx')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">WORD
          </div>
          <div v-show="clas==='tran'" :class="comment==='commentsone'?'select':''" @click="checkComment('commentsone')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">原文批注
          </div>
          <div v-show="clas==='tran'" :class="comment==='commentstwo'?'select':''" @click="checkComment('commentstwo')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">译文批注
          </div>
        </div>
      </div>
      <div style="width: 100%;display: flex;align-items: center;justify-content: center" slot="footer">
        <div style="cursor: pointer;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;margin-bottom: 6px;
   width: 100px;height: 32px;background: linear-gradient(-50deg, #231815, #453835);opacity: 0.96;border-radius: 5px;
display: flex;align-items: center;justify-content: center" @click="confirmDown">
          确 认
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkStatusRequest,
  downloadRequest,
  facetRequest,
  queryRequest,
  transDocRequest,
  searchServiceClient, collRequest
} from "@/tools/search-api";
import merge from 'webpack-merge'
import classCode from "../../classCode.json"
import Empty from '@/components/Empty'
import language from "../../language.json"
import classCodeZh from "../../classCodeZh.json"
import languageZh from "../../languageZh.json"
import jwt from "jwt-decode";
import vueQr from "vue-qr"

export default {
  name: "Periodical",
  components: {
    Empty,
    vueQr
  },
  data() {
    return {
      downCenterDialogVisible: false,
      iframeSrc: '',
      commonUrl: '',
      qqQrcodeCenterDialogVisible: false,
      qrcodeCenterDialogVisible: false,
      setMailVisible: false,
      language: language,
      languageZh: languageZh,
      centerDialogVisible: false,
      classCode: classCode,
      classCodeZh: classCodeZh,
      facetField: ["年份", "学科分类", "语种", "刊名", "作者", "作者单位", "刊名_Translated", "作者单位_Translated"],
      facetFieldData: [],
      facetFieldDataClassCode: [],
      facetFieldDataZh: [],
      checkList: [],
      pageSize: 10,
      currentPage: 1,
      currentPageFacet: 1,
      pageSizeFacet: 5,
      filter: [],
      filterNode: [],
      word: '',
      select: '所有领域',
      collection: '',
      oldCollection: '',
      data: [],
      docIds: {},
      remarkMap: {},
      checkRemarkDoc: {},
      totalCount: 0,
      loading: true,
      isCheck: false,
      showSelect: false,
      sortField: "",
      sortOrder: 3,
      absData: [],
      downLoadList: [],
      isShowTransDoc: undefined,
      clas: '',
      downLoadItem: undefined,
      radio: 'pdf',
      comment: '',
      transTimer: undefined,
      isOk: false,
      oldToken: '',
      checkLogin: undefined,
      index: 0,
      collDocLoading: -1
    }
  },
  created() {
    let filter = this.$route.query.filter || []
    if (typeof (filter) === 'string') {
      filter = [filter]
    }
    this.currentPage = parseInt(this.$route.query.currentPage || 1)
    this.pageSize = parseInt(this.$route.query.pageSize || 10)
    this.filter = filter
    this.word = this.$route.query.word
    this.select = this.$route.query.select || '所有领域'
    this.collection = this.$route.query.collection || 'Periodical'
    this.sortField = this.$route.query.sortField || ''
    this.sortOrder = parseInt(this.$route.query.sortOrder || 3)
    this.trans = JSON.parse(this.$route.query.trans || true)
    this.queryAbs()
    this.toSearch()
    let isShowTransDoc = localStorage.getItem("isShowTransDoc")
    if (isShowTransDoc) {
      this.isShowTransDoc = isShowTransDoc
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    }
  },
  updated() {
    this.showSelect = document.getElementById('facet-select')?.scrollWidth > 650
  },
  computed: {
    trans() {
      return this.$store.state.trans
    },
  },
  watch: {
    filter(newVal, oldVal) {
      let that = this
      this.filterNode = []
      this.filter.forEach(item => {
        item = item.substr(item.indexOf(":") + 1).trim();
        if (item.indexOf('*') > 0) {
          if (item.length === 2) {
          } else if (item.length === 4) {
            try {
              that.filterNode.push(parseInt(item.substr(0, 1)))
            } catch (e) {
            }
          } else {
            try {
              that.filterNode.push(parseInt(item.substr(0, 3)))
            } catch (e) {
            }
          }
        }
      })
    },
    collection(newVal, oldVal) {
      if (this.oldCollection !== '') {
        this.oldCollection = ''
        return;
      }
      if (this.word === '' || this.isEmpty(this.word)) {
        this.collection = oldVal
        this.oldCollection = oldVal
        this.$message.warning("请输入检索式")
        return
      }
      this.$router.push({
        name: newVal,
        query: {
          collection: newVal,
          select: this.select,
          word: this.word,
          currentPage: 1,
          pageSize: 10,
          filter: [],
          trans: this.trans
        }
      })
    }
  },
  methods: {
    clearClas() {
      this.clas = ''
      this.comment = ''
      this.downCenterDialogVisible = false
    },
    shareTo(stype, ftit, summary, id) {
      ftit = ftit.replaceAll("<span style='color:rgba(245, 108, 108, 1)'>", "")
          .replaceAll("</span>", "")
      summary = summary.replaceAll("<span style='color:rgba(245, 108, 108, 1)'>", "")
          .replaceAll("</span>", "")
      let href = encodeURIComponent("https://hy.wesitrans.com" + this.$router.resolve({
        name: 'Document',
        query: {
          collection: 'Periodical',
          id: id,
          trans: true
        }
      }).href);
      //qq空间接口的传参
      if (stype === 'qzone') {
        window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
            href + '?sharesource=qzone&title=' + ftit + '&summary=' + summary);
      }
      //新浪微博接口的传参
      if (stype === 'sina') {
        window.open('https://service.weibo.com/share/share.php?url=' + href +
            '?sharesource=weibo&title=' + ftit + '&appkey=4030027943');
      }
      //qq好友接口的传参
      if (stype === 'qq') {
        this.iframeSrc = 'https://connect.qq.com/widget/shareqq/index.html?url=' + href +
            '?sharesource=qzone&title=' + ftit + '&summary=' + summary +
            '&desc=浩语，突破翻译，发现文章';
        this.qqQrcodeCenterDialogVisible = true
      }
      //生成二维码给微信扫描分享，php生成，也能够用jquery.qrcode.js插件实现二维码生成
      if (stype === 'wechat') {
        this.commonUrl = decodeURIComponent(href)
        this.qrcodeCenterDialogVisible = true
      }
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3]);
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    toList() {
      this.isOk = false;
      this.$router.push('/list')
    },
    closes(id) {
      let isShowTransDoc = JSON.parse(this.isShowTransDoc)
      let docs = []
      isShowTransDoc.forEach((item, index) => {
        if (item.id === id) {
          return
        }
        docs.push(item)
      })
      if (docs.length === 0) {
        this.isShowTransDoc = undefined
        localStorage.removeItem("isShowTransDoc")
        return
      }
      this.isShowTransDoc = JSON.stringify(docs)
      localStorage.setItem("isShowTransDoc", this.isShowTransDoc)
    },
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`;
    },
    onClickSearch(word) {
      this.select = '所有领域'
      this.word = '关键词:\"' + word + '\"'
      this.toSearch('new')
    },
    sort(sortOrder) {
      if (sortOrder === 0 || sortOrder === 1) {
        this.sortField = 'PublishYear'
      } else {
        this.sortField = ''
      }
      this.sortOrder = sortOrder
      this.toSearch()
    },
    right() {
      document.getElementById('facet-select').scrollLeft -= 600
    },
    left() {
      document.getElementById('facet-select').scrollLeft += 600
    },
    clearSelect() {
      this.filter = []
      this.toSearch()
    },
    convert(item) {
      item = item.substr(item.indexOf(":") + 1).trim();
      if (item.indexOf('*') > 0) {
        if (item.length === 2) {
          item = item.substr(0, 1)
        } else if (item.length === 4) {
          item = item.substr(1, 2)
        } else {
          item = item.substr(1, 4)
        }
      } else {
        item = JSON.parse(item.trim())
      }
      if (this.trans) {
        return this.classCodeZh[item] || this.languageZh[item] || JSON.parse(JSON.stringify(item))
      }
      return this.classCode[item] || this.language[item] || JSON.parse(JSON.stringify(item))
    },
    deleteFact(index) {
      let arr = this.filter.slice(0, index) || []
      let arr2 = this.filter.slice(index + 1, this.filter.length) || []
      arr.push(...arr2)
      this.filter = arr
      this.toSearch()
    },
    handleCheckedFactChange(val) {
      this.currentPage = 1
      this.pageSize = 10
      this.toSearch()
    },
    clearCheck() {
      this.checkList = []
    },
    transTo() {
      this.trans = !this.trans
      this.$router.push({
        query: merge(this.$route.query,
            {
              filter: null
            }
        )
      })
      this.$router.push({
        query: merge(this.$route.query,
            {
              collection: this.collection,
              select: this.select,
              word: this.word,
              currentPage: this.currentPage,
              pageSize: this.pageSize,
              filter: this.filter,
              sortField: this.sortField,
              trans: this.trans
            }
        )
      })
    },
    handleCheckedCitiesChange(val) {
      if (this.checkList.length > 10) {
        this.checkList = this.checkList.slice(0, 10)
        this.$message.warning("暂时只支持批量选择10篇文献")
      }
    },
    handleCheckedAllChange(isCheck) {
      if (this.checkList.length === 10) {
        let checkList = this.checkList
        this.data.forEach(data => {
          if (data.Translated_Status && data.Translated_Status === 'ok') {
            return
          }
          checkList.push(JSON.stringify(data))
        })
        checkList = Array.from(new Set(checkList));
        if (checkList.length > 10) {
          if (checkList.length > 10) {
            this.checkList = checkList.slice(0, 10)
          }
          this.$message.warning("暂时只支持批量选择10篇文献")
          return
        }
      }
      this.isCheck = isCheck
      if (isCheck) {
        this.data.forEach(data => {
          if (data.Translated_Status && data.Translated_Status === 'ok') {
            return
          }
          this.checkList.push(JSON.stringify(data))
        })
        this.checkList = Array.from(new Set(this.checkList));
      } else {
        this.checkList = []
      }
    },
    formatCount(val) {
      let nVal = ['', '', ''];
      let fr = 1000;
      let num = 3;
      while (val / fr >= 1) {
        fr *= 10;
        num += 1;
      }
      if (num <= 4) { // 千
        nVal[1] = '千+';
        nVal[0] = parseInt(val / 1000) + '';
      } else if (num <= 8) { // 万
        const str = parseInt(num - 4) / 3 > 1 ? '千万+' : '万+';
        const fm = '万+' === str ? 10000 : 10000000;
        nVal[1] = str;
        nVal[0] = (val / fm) + '';
      } else if (num <= 16) {// 亿
        let str = (num - 8) / 3 > 1 ? '千亿+' : '亿+';
        str = (num - 8) / 4 > 1 ? '万亿+' : str;
        str = (num - 8) / 7 > 1 ? '千万亿+' : str;
        let fm = 1;
        if ('亿+' === str) {
          fm = 100000000;
        } else if ('千亿+' === str) {
          fm = 100000000000;
        } else if ('万亿+' === str) {
          fm = 1000000000000;
        } else if ('千万亿+' === str) {
          fm = 1000000000000000;
        }
        nVal[1] = str;
        nVal[0] = parseInt(val / fm) + '';
      }
      if (val < 1000) {
        nVal[1] = '';
        nVal[0] = val + '';
      }
      return nVal.join('');
    },
    toPageChange(val) {
      this.currentPage = Math.ceil(val)
      this.toSearch()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.toSearch()
    },
    toMore(item, name, num) {
      let request = facetRequest;
      request.setCurrentPage(num)
      request.setPageSize(this.pageSizeFacet)
      request.setFilterQueryList(this.filter)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection(this.collection)
      request.setFacetFieldList([name])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        let documentJson = JSON.parse(document)
        this.facetFieldData.forEach((item, index) => {
          if (item.name === documentJson[0].name) {
            if (documentJson[0].valueCount === 0) {
              item.hasMore = false
            }
            item.valueCount += documentJson[0].valueCount
            item.values = [...item.values, ...documentJson[0].values]
          }
        })
        this.facetFieldDataZh.forEach((item, index) => {
          if (item.name === documentJson[0].name) {
            if (documentJson[0].valueCount === 0) {
              item.hasMore = false
            }
            item.valueCount += documentJson[0].valueCount
            item.values = [...item.values, ...documentJson[0].values]
          }
        })
      }).catch(err => {
        console.log(err)
      })
    },
    downLoad(item, clas) {
      this.clas = clas
      this.downLoadItem = item
    },
    checkComment(command) {
      this.radio = command !== 'pdf' && command !== 'docx' ? 'docx' : command
      this.comment = command
    },
    handleOrgCommand(command, item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.clas = ''
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      this.downLoadItem = item
      this.downCenterDialogVisible = true
    },
    confirmDown() {
      this.downCenterDialogVisible = false
      this.downLoadList.push(this.downLoadItem)
      this.downloadDoc()
      this.clas = ''
      this.comment = ''
    },
    downloadDoc() {
      let request = downloadRequest;
      request.setId(this.downLoadItem.id);
      request.setType(this.radio)
      request.setClasses(this.clas)
      request.setComment(this.comment)
      request.setName(this.downLoadItem.Title)
      const that = this;
      const TOKEN_KEY = 'token';
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let stream = searchServiceClient.download(request, {
        [TOKEN_KEY]: token
      });
      let flag = 0;
      let content = "";
      let title = "";
      let type = "";
      let message = '';
      stream.on('data', (res) => {
        message = res.getMessage()
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() && flag === 0) {
          title = res.getTitle();
          type = res.getType();
        }
        if (!res.getStatus() && flag === 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: res.getMessage()
          });
          that.centerDialogVisible = false;
          return;
        }
        flag++;
        content += res.getContent_asB64();
      });
      stream.on('end', (end) => {
        if (message === 'login') {
          return
        }
        if (message === 'login-person') {
          return;
        }
        let aLink = document.createElement('a');
        let blob = that.base64ToBlob(content, type);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = title;
        aLink.href = window.URL.createObjectURL(blob)
        aLink.click();
        that.centerDialogVisible = false;
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
      });
      stream.on('error', (err) => {
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
        that.centerDialogVisible = false;
        if (err && err.code !== 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: '下载文档失败，请稍后再试！'
          })
        }
      });
    },
    base64ToBlob(code, type) {
      let bstr = atob(code)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: type});
    },
    readView(item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      this.$router.push({
        name: "ReadView",
        query: {
          collection: this.collection,
          id: item.id,
          trans: this.trans
        }
      })
    },
    batchTransDoc() {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      if (!this.checkList || this.checkList.length === 0) {
        this.$message.warning("请选择要翻译的文章")
        return
      }
      this.checkList.forEach(item => {
        this.transDoc(JSON.parse(item))
      })
    },
    toSetMail() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    collDoc(item, index) {
      this.collDocLoading = index
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.collDocLoading = -1
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let href = "https://hy.wesitrans.com" + this.$router.resolve({
        name: 'Document',
        query: {
          collection: 'Periodical',
          id: item.id,
          trans: true
        }
      }).href;
      let request = collRequest
      request.setDocId(item.id)
      request.setCollection('Periodical')
      request.setUrl(href)
      searchServiceClient.coll(request, {
        ['token']: token
      }).then(res => {
        if (res.getMessage() === "login") {
          this.collDocLoading = -1
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getMessage() === 'login-person') {
          this.collDocLoading = -1
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getMessage() === "remote") {
          this.collDocLoading = -1
          this.$message.error("当前账户不具备收藏权限,请联系销售人员！")
          return
        }
        if (!res.getStatus()) {
          this.collDocLoading = -1
          this.$message.error(res.getMessage())
          return
        }
        this.collDocLoading = -1
        this.$message.success(res.getMessage())
        if (this.docIds[item.id]) {
          this.docIds[item.id] = !this.docIds[item.id]
        } else {
          this.docIds[item.id] = true
        }
      }).catch(err => {
        this.collDocLoading = -1
        this.$message.error("收藏异常，请稍后再试！")
      })
    },
    transDoc(item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let isShowTransDoc = localStorage.getItem("isShowTransDoc")
      if (isShowTransDoc) {
        let docs = JSON.parse(isShowTransDoc)
        for (let i = 0; i < docs.length; i++) {
          if (!docs[i].id) {
            localStorage.removeItem("isShowTransDoc")
          } else {
            if (docs[i].id === item.id) {
              this.$message.warning("正在翻译啦，请不要着急！")
              return;
            }
          }
        }
        let request = transDocRequest
        request.setId(item.id)
        searchServiceClient.transDoc(request, {
          ['token']: token
        }).then(res => {
          if (res.getStatus() === "login") {
            this.showIframe('/loginTwo?code=haoyi')
            return
          }
          if (res.getStatus() === 'login-person') {
            this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
            this.showIframe('/loginTwo?code=haoyi')
            return;
          }
          if (res.getStatus() === "remote") {
            this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
            return
          }
          if (res.getStatus() === "set_mail") {
            this.setMailVisible = true
            return
          }
          if (res.getStatus() === "fail") {
            this.$message.error("翻译失败，请稍后再试！")
            return
          }
          if (res.getStatus() === "time") {
            this.$message.error("您的机构使用时间已截至,请联系销售人员！")
            return
          }
          if (res.getStatus() === "success") {
            docs.push({
              id: item.id,
              Title: item.Title
            })
            localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
            this.isShowTransDoc = JSON.stringify(docs)
            return
          }
          this.$message.error(res.getStatus())
        }).catch(err => {
          this.$message.error("翻译异常，请稍后再试！")
        })
        return
      }
      let request = transDocRequest
      request.setId(item.id)
      searchServiceClient.transDoc(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus() === "login") {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getStatus() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() === "remote") {
          this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
          return
        }
        if (res.getStatus() === "set_mail") {
          this.setMailVisible = true
          return
        }
        if (res.getStatus() === "fail") {
          this.$message.error("翻译失败，请稍后再试！")
          return
        }
        if (res.getStatus() === "time") {
          this.$message.error("您的机构使用时间已截至,请联系销售人员！")
          return
        }
        if (res.getStatus() === "success") {
          localStorage.setItem("isShowTransDoc", JSON.stringify([{id: item.id, Title: item.Title}]))
          this.isShowTransDoc = JSON.stringify([{id: item.id, Title: item.Title}])
          return
        }
        this.$message.error(res.getStatus())
      }).catch(err => {
        this.$message.error("翻译异常，请稍后再试！")
      })
      clearInterval(this.transTimer)
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    },
    checkStatus() {
      if (this.isShowTransDoc) {
        let docs = JSON.parse(this.isShowTransDoc)
        let isOk = true
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].number === 100) {
            continue
          }
          isOk = false
          let Range = 5 - 1;
          let Rand = Math.random();
          let num = 1 + Math.round(Rand * Range) + parseInt(docs[i].number || 0)
          docs[i].number = num > 99 ? 99 : num
          let request = checkStatusRequest
          request.setDocId(docs[i].id)
          let token = this.$cookies.get('token')
          if (!token) {
            token = localStorage.getItem('token');
          }
          searchServiceClient.checkStatus(request, {
            ['token']: token
          }).then(res => {
            if (res.getStatus()) {
              docs[i].number = 100;
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            } else {
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            }
          }).catch(err => {
            console.log(err)
          })
        }
        if (isOk) {
          clearInterval(this.transTimer)
          localStorage.removeItem("isShowTransDoc")
          this.isShowTransDoc = undefined;
          this.isOk = isOk
        }
      } else {
        clearInterval(this.transTimer)
      }
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        let index = window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
        for (let i = 1; i < index; i++) {
          window.layer.close(i)
        }
      } else {
        let index = window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
        for (let i = 1; i < index; i++) {
          window.layer.close(i)
        }
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    toDocument() {
    },
    isEmpty(val) {
      if (val == null) return true;
      if (typeof val === 'boolean') return false;
      if (typeof val === 'number') return !val;
      if (val instanceof Error) return val.message === '';
      switch (Object.prototype.toString.call(val)) {
        case '[object String]':
        case '[object Array]':
          return !val.length;
        case '[object File]':
        case '[object Map]':
        case '[object Set]': {
          return !val.size;
        }
        case '[object Object]': {
          return !Object.keys(val).length;
        }
      }
      return false;
    },
    facet() {
      if (this.word === '' || this.isEmpty(this.word)) {
        this.$message.warning("请输入检索式")
        return
      }
      let request = facetRequest;
      request.setCurrentPage(this.currentPageFacet)
      request.setPageSize(this.pageSizeFacet)
      request.setFilterQueryList(this.filter)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection(this.collection)
      request.setFacetFieldList(this.facetField)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facetClassCode(request, {
        ['token']: token
      }).then(res => {
        console.log(res)
        let document = res.getDocument()
        if (!document) {
          return
        }
        this.facetFieldDataClassCode = JSON.parse(document);
      }).catch(err => {
        console.log(err)
      })
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询聚类为空")
          return
        }
        let facetArray = JSON.parse(document);
        let arr = facetArray.slice(0, 3) || []
        let arr2 = facetArray.slice(4, 5) || []
        let arr3 = facetArray.slice(6, 7) || []
        let arr4 = facetArray.slice(7, 8) || []
        arr.push(...arr3)
        arr.push(...arr2)
        arr.push(...arr4)
        this.facetFieldDataZh = arr
        this.facetFieldData = facetArray.slice(0, 6) || []
      }).catch(err => {
        console.log(err)
      })
    },
    clearWord() {
      this.word = ""
    },
    queryAbs() {
      if (this.word === '' || this.isEmpty(this.word)) {
        return
      }
      let request = facetRequest;
      request.setCurrentPage(1)
      request.setPageSize(5)
      request.setDefaultField(this.select)
      request.setFacetQueryWord(this.word)
      request.setCollection('Periodical')
      request.setFacetFieldList(['关键词'])
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.facet(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          return
        }
        this.absData = JSON.parse(document)
      }).catch(err => {
        console.log(err)
      })
    },
    toOther(sle, val) {
      this.select = sle
      this.word = val
      this.toSearch('new')
    },
    checkSource(collection) {
      this.collection = collection
    },
    toSearch(val) {
      if (this.word === '' || this.isEmpty(this.word)) {
        this.$message.warning("请输入检索式")
        return
      }
      if (this.word === '*' || this.word.toLowerCase() === 'and' || this.word.toLowerCase() === 'or'
          || this.word === '!' || this.word.toLowerCase() === 'not') {
        this.$message.warning("检索关键词中包含非法字符，请修改后重新检索")
        return
      }
      if (val === 'new') {
        this.queryAbs()
        this.filter = []
        this.currentPage = 1
        this.pageSize = 10
        this.$router.push({
          query: merge(this.$route.query,
              {
                collection: this.collection,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: null,
                sortField: null,
                sortOrder: null,
                trans: this.trans
              }
          )
        })
      } else {
        this.$router.push({
          query: merge(this.$route.query,
              {
                filter: null
              }
          )
        })
        this.$router.push({
          query: merge(this.$route.query,
              {
                collection: this.collection,
                select: this.select,
                word: this.word,
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                filter: this.filter,
                sortField: this.sortField,
                trans: this.trans,
                sortOrder: this.sortOrder
              }
          )
        })
      }
      this.facet();
      let request = queryRequest
      request.setCurrentPage(this.currentPage)
      request.setPageSize(this.pageSize)
      request.setFilterQueryList(this.filter)
      request.setSortField(this.sortField)
      request.setSortOrder(this.sortOrder)
      request.setDefaultField(this.select)
      request.setQueryWord(this.word)
      request.setCollection(this.collection)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      this.loading = true
      searchServiceClient.query(request, {
        ['token']: token
      }).then(res => {
        this.loading = false
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询为空")
          return
        }
        this.remarkMap = JSON.parse(res.getJsonremark())
        this.checkRemarkDoc = {}
        for (let key in this.remarkMap) {
          this.remarkMap[key].forEach((item, index) => {
            if (item.check) {
              this.checkRemarkDoc[key] = item.check
            }
          })
        }
        this.docIds = JSON.parse(res.getDocIdJson());
        let parse = JSON.parse(document);
        this.data = parse.documents || []
        this.totalCount = parse.numFound || 0
      }).catch(err => {
        this.totalCount = 0
        this.loading = false
        console.log(err)
        this.$message.warning("查询失败")
      })
    }
  },
  destroyed() {
    clearInterval(this.checkLogin)
    clearInterval(this.transTimer)
  }
}
</script>
<style lang="less">
.el-dropdown-menu{
  .el-dropdown-menu__item{
    .el-radio {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
    }

    .el-radio__label {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .el-radio__input.is-checked + .el-radio__label {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
      transform: rotate(45deg) scaleY(0);
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #E83428;
      background: #E83428;
      width: 9px;
      height: 9px;
      background: #E83428;
      border-radius: 50%;
    }

    .el-radio__inner {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 0px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-radio__input {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-radio__inner::after {
      background: #F5F5F5;
    }
  }
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.option div {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  width: 81px;
  height: 30px;
  background: #F5F5F5;
  border-radius: 5px;
}

.option div.select {
  background: #F5F5F5;
  border: 1px solid #E83428;
  position: relative;
}

.option div.select::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-bottom: 24px solid #E83428;
  border-left: 23px solid transparent;
}

.option div.select::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 4px;
  background: transparent;
  bottom: 6px;
  right: 0px;
  border: 2px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(-55deg);
  z-index: 9;
}


.el-button--text {
  color: rgb(153, 153, 153);
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }

  .el-select-dropdown__item.selected {
    color: #231815;
    font-weight: 700;
  }
}
</style>
<style lang="less" scoped>
.check-remark {
  background: #E83428 !important;
  font-size: 16px !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
}

.home {
  display: flex;
  width: 100%;
  flex-flow: column;

  /deep/ .downs {
    .el-icon-check:before {
      content: url('');
      display: block;
      width: 14px;
      height: 14px;
      background: url('../assets/downico.png');
      background-size: cover;
    }
  }

  /deep/ .dialog {
    .el-dialog__headerbtn .el-dialog__close {
      color: #909399;
      margin-right: -70px;
      margin-top: -55px;
      display: block;
    }

    .checks {
      .el-radio__label {
        padding-left: 10px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666 !important;
      }

      .el-radio__inner {
        width: 20px;
        height: 20px;
        background: #F5F5F5;
        border: 1px solid #DDDDDD;
        border-radius: 10px;
      }

      .el-radio__inner::after {
        width: 9px;
        height: 9px;
        background: #231815;
        border-radius: 4px;
      }
    }

    .el-icon-close:before {
      content: url("../assets/close-dialog.png");
    }

    .el-dialog__title {
      width: 479px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      line-height: 24px;
      margin-top: 33px;
      display: flex;
    }

    .el-dialog__header {
      padding: 20px 20px 10px;
      display: flex;
      justify-content: center;
    }

    .el-dialog__body {
      padding: 0;
      display: flex;
      justify-content: center;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 14px;
      color: #666666;
    }
  }

  /deep/ .isShowTransDoc {
    .el-progress-bar {
      padding-right: 50px;
      width: 117%;
      margin-right: -54px;
      box-sizing: border-box;
    }

    .el-progress__text {
      margin-top: -35px;
      margin-left: -55px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #5F8ACA;
    }
  }

  /deep/ .aside {
    .el-icon-arrow-down:before {
      content: url("../assets/sanjiao.png");
    }

    .el-tree-node__content {
      .el-icon-caret-right:before {
        content: url("../assets/sanjiao.png");
      }

      .el-tree-node__expand-icon.is-leaf {
        transform: translateX(1000px);
      }

      .el-tree-node__label {
        font-size: 14px;
        flex: 1;
      }

      .el-tree-node__expand-icon {
        padding: 0;
        margin-right: 12px;
        order: 1;
        transform: rotate(90deg);
      }

      .el-tree-node__expand-icon.expanded {
        transform: rotate(0deg);
      }
    }


    .el-menu {
      border: 0;
      overflow-x: hidden;
    }

    .el-menu::-webkit-scrollbar {
      width: 4px;
    }

    .el-menu::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .el-menu::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .el-submenu .el-menu {
      overflow-y: auto;
      max-height: 300px;
      width: 275px;
      overflow-x: hidden;
    }

    .el-checkbox__label {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #E83428;
    }

    .el-menu-item:hover {
      background: white;
    }

    .el-submenu__title:hover {
      background: white;
    }

    .el-submenu__title {
      padding-left: 0 !important;

      .el-submenu__icon-arrow {
        right: 0;
      }
    }

    .el-checkbox__inner {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 0px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-checkbox__input {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-checkbox__inner::after {
      background: #F5F5F5;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
      transform: rotate(45deg) scaleY(0);
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #E83428;
      width: 9px;
      height: 9px;
      background: #E83428;
      border-radius: 50%;
    }
  }

  /deep/ .main-content {
    .box-card {
      margin-bottom: 17px;
    }

    .el-card {
      width: 890px;
      min-height: 186px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
      margin-left: 25px;

      .el-menu {
        border: 0;
      }

      .el-card__body {
        padding-top: 20px;
        padding-left: 10px;

        .el-submenu__title:hover {
          background: white;
        }

        .el-submenu__title {
          padding-left: 0 !important;
          width: 100px;

          .el-icon-arrow-down:before {
            content: url("../assets/sanjiao.png");
          }

        }

        .el-checkbox__input {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          border: 1px solid #DDDDDD;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__inner {
          width: 24px;
          height: 24px;
          background: #FFFFFF;
          background: #FFFFFF;
          border: 0px solid #DDDDDD;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          transform: rotate(45deg) scaleY(0);
        }

        .el-checkbox__input.is-checked .el-checkbox__inner {
          border-color: #E83428;
          width: 14px;
          height: 14px;
          background: #E83428;
        }
      }
    }
  }

  /deep/ .el-container.is-vertical {
    background: url("../assets/banner.png") no-repeat;
    background-size: 100% 202px;
    align-items: center;
    width: 100%;
  }

  /deep/ .el-header {
    padding: 0;
  }

  /deep/ .el-main {
    padding: 0;
    background: white;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  /deep/ .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .check-source {
    display: flex;
    cursor: pointer;

    .check-active {
      width: 96px;
      height: 36px;
      background: #DCDED9;
      border-radius: 5px 5px 0px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #231815;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .check-no-active {
      width: 96px;
      height: 36px;
      background: rgb(220 222 217 / 0%);
      border-radius: 5px 5px 0px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #231815;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .onClickSearch:hover {
    background: #DCDED9;
  }

  /deep/ .search-input {
    height: 51px;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;

    .el-select .el-input .el-select__caret.is-reverse {
      transform: rotateZ(180deg);
    }

    .el-select .el-input .el-select__caret {
      transform: rotateZ(0);
    }

    .btn-search {
      width: 110px;
      height: 51px;
      background: linear-gradient(-50deg, #231815, #453835);
      opacity: 0.96;
      border-radius: 0px 5px 5px 0px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }

    .el-input__suffix {
      display: flex;
      align-items: center;
      padding-right: 16px;
      cursor: pointer;
    }

    .el-icon-arrow-up:before {
      content: url("../assets/sanjiao.png");
    }

    .el-input-group {
      height: 51px
    }

    .el-input-group--append .el-input__inner, .el-input-group__prepend {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 51px;
      border: 0;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }

    .el-input-group__prepend {
      border: 0;
      background: white;
      width: 170px;
      padding-right: 0;
      border-right: 1px solid #DDDDDD;

      .el-input__inner {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 56px;
      }
    }
  }

  /deep/ .pages {
    .el-pagination {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;

      .el-pagination__total {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }

      .btn-prev {
        font-size: 14px;
      }

      .btn-next {
        font-size: 14px;
      }

      .el-pager li {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        background: #F5F5F5;
        margin: 0 5px;
      }

      .el-pager li.active {
        background: #231815;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  a {
    text-decoration: none;
  }

  .text {
    white-space: normal;
    text-align: justify;
    width: 820px;
  }

  .facet-item {
    padding-left: 0px !important;
    display: flex;
    align-items: center;
  }
}
</style>