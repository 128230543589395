<template>
  <div style="width: 100%;display: flex;justify-content: center;background: #F5F5F5;">
    <div v-show="!trans"
         style="width:100%;display: flex;flex-flow: column;justify-content: center;align-items: center;">
      <div style="display: flex; align-items: flex-start;justify-content: space-between;width: 1200px;">
        <div style="margin-top: 52px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;
              display: flex;align-items: center;"
             v-if="doc.Title">
          <span v-html="doc.Title"></span>
          <span v-show="doc.Translated_Status&&doc.Translated_Status==='ok'"
                style="display: flex;padding-left: 10px;">
            <img src="../assets/yi.png" alt="已译"></span>
        </div>
      </div>
      <div
          style="width: 1200px;margin-top: 15px;font-size: 14px;font-family: Source Han Sans CN;font-weight: bold;color: #666666;"
          v-if="doc.PeriodicalTitle">
        <span v-html="doc.PeriodicalTitle"></span>
        <span v-if="doc.PublishDate">（{{ doc.PublishDate }}）</span>
      </div>
      <div
          style="width: 1200px;cursor: pointer;display: flex;align-items: end;height: 50px;font-size: 14px;
          font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
        <div style="width: 170px;display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: center;justify-content: space-between;width: 70px;">
            <img src="../assets/share-active.png" alt=""/>
            <el-popover
                placement="bottom"
                width="140"
                trigger="hover">
              <div
                  style="height: 140px;display: flex;flex-flow: column;justify-content: space-between;">
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('wechat',doc.Title,doc.Abstract,doc.id)">
                  <img src="../assets/wechar.png" width="24" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    微信
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('sina',doc.Title,doc.Abstract,doc.id)">
                  <img src="../assets/webo.png" width="23" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    微博
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('qq',doc.Title,doc.Abstract,doc.id)">
                  <img src="../assets/qq.png" width="20" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    QQ
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('qzone',doc.Title,doc.Abstract,doc.id)">
                  <img src="../assets/qzone.png" width="24" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    QQ空间
                  </div>
                </div>

              </div>
              <el-button type="text" slot="reference">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                转发
                              </span>
              </el-button>
            </el-popover>
          </div>
          <div @click="collDoc(doc)"
               style="display: flex;align-items: center;justify-content: space-between;width: 70px;">
            <img v-show="!hasColl" src="../assets/collection-active.png" alt=""/>
            <img v-show="hasColl" src="../assets/collection-ok.png" alt=""/>
            <el-button type="text" :loading="collDocLoading">
                <span
                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  收藏
                </span>
            </el-button>
          </div>
        </div>
      </div>
      <el-menu style="width: 1200px;" v-if="doc.Abstract" class="el-menu-vertical-demo"
               :default-openeds="['1']">
        <el-submenu index="1">
          <template slot="title">
            <span
                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;">摘要</span>
          </template>
          <span
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;line-height: 30px;"
              v-html="doc.Abstract"></span>
        </el-submenu>
      </el-menu>
      <div class="info">
        <div v-if="doc.DOI"><span>DOI:</span>{{ doc.DOI }}</div>
        <div v-if="doc.Creator">
          <span>作者:</span>
          <span v-for="(item,index) in doc.Creator.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("作者:\""+item+"\"")'>{{ item }}</span>
            {{ doc.Creator.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.ForeignKeywords">
          <span>关键词:</span>
          <span v-for="(item,index) in doc.ForeignKeywords.split(';')||[]">
            <span style="cursor: pointer" @click='toSearchWord("关键词:\""+item+"\"")'>{{ item }}</span>
            {{ doc.ForeignKeywords.split(';').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.OriginalOrganization">
          <span>作者单位:</span>
          <span v-for="(item,index) in doc.OriginalOrganization.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("作者单位:\""+item+"\"")'>{{ item }}</span>
            {{ doc.OriginalOrganization.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.PublishYear"><span>年、卷（期）:</span>{{ doc.PublishYear }}，{{
            doc.Volum
          }}{{ doc.Issue ? "（" + doc.Issue + "）" : '' }}
        </div>
        <div v-if="doc.OriginalClassCode"><span>分类号:</span>
          <span v-for="(item,index) in doc.OriginalClassCode.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("分类号:*"+item.trim().substr(1, 4)+"*")'>{{
                classCodeZh[item.trim().substr(1, 4)] || item
              }}</span>
            {{ doc.OriginalClassCode.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div class="item" v-show="doc.HasFulltext&&doc.HasFulltext.toLowerCase()==='y'">
          <div class="inner">
            <div @click="readView(doc)" class="common-back">
              <img src="../assets/read-detail.png" alt=""/>
              在线阅读
            </div>
            <el-dropdown v-show="hasRemark" @command="handleOrgCommand($event,doc)">
              <div class="common-back">
                <img src="../assets/down-detail.png" alt=""/>
                下载
              </div>
              <el-dropdown-menu slot="dropdown"
                                style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                <el-dropdown-item
                    style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                    command="org">
                  <el-radio v-model="clas" label="org">原文</el-radio>
                </el-dropdown-item>
                <el-dropdown-item
                    style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                    command="tran">
                  <el-radio v-model="clas" label="tran">译文</el-radio>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popover v-show="!hasRemark"
                        placement="bottom"
                        width="239"
                        trigger="hover">
              <div
                  style="width: 100%;display:flex;justify-content: center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                请阅读详情并 <a href="#miao"><span style="cursor: pointer;color: #A2CCCA;padding: 0 10px">进行评价</span></a> 即可下载
              </div>
              <div class="common-back-none" slot="reference">
                <img src="../assets/down-detail.png" alt=""/>
                <span>下载</span>
              </div>
            </el-popover>
            <div @click="transDoc(doc)" v-show="!doc.Translated_Status||doc.Translated_Status!=='ok'"
                 class="common-back">
              <img src="../assets/trans-detail.png" alt=""/>
              翻译
            </div>
          </div>
        </div>
      </div>
      <div class="send" id="miao">
        <div style="width: 1200px;display: flex;justify-content: space-between;">
          <div style="width: 760px;margin-top: 25px;">
            <span v-if="jsonRemarkType!==undefined" v-for="(item,index) in jsonRemarkType||[]" :key="index+'_remark'">
              <div v-show="item.remark" style="display: flex;
    align-items: center;
    justify-content: center;width: 100%;font-size: 26px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              <div style="width: 50px;
height: 1px;
background: #DDDDDD;">
              </div>
              <div style="display: flex;
    align-items: center;
    justify-content: center;font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;line-height: 56px;padding: 0 8px">{{ item.remark }}</div>
              <div style="width: 50px;
height: 1px;
background: #DDDDDD;">
              </div>
            </div>
            <div style="width: 100%;margin-top: 26px;display: flex;flex-wrap: wrap;">
              <div v-for="(childItem,childIndex) in item.childRemarkTypes||[]" :key="childIndex+'_child_remark'"
                   @click="checkRemark(childItem)"
                   :style="`background-image: linear-gradient(to right ,#EEEEEE ${childItem.count>0?valCount(childItem.count):100}%, #F8F8F9 0%)`"
                   style="justify-content: space-between;width: 44%;margin-bottom:10px;cursor: pointer;padding: 0px 15px;height: 40px;border-radius: 5px;font-size: 16px;margin-right: 10px;
                              font-family: Source Han Sans CN;font-weight: 400;color: #666666;display: flex;align-items: center;">
                {{ childItem.name }}<span style="margin-right: 5px"></span><span
                  v-show="childItem.count>0">{{ formatCount(childItem.count) }}</span>
              </div>
            </div>
            <div
                style="margin-top: 24px;width: 720px;height: 1px;"></div>
            </span>
          </div>
          <div v-show="magazineDoc&&magazineDoc.Title"
               style="width: 340px;margin-top: 22px;background: #F5F5F5;display: flex;align-items: center;
              flex-flow: column;">
            <img src="../assets/default_perio_img.png" alt=""
                 style="margin-top: 27px;box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);border-radius: 5px;"/>
            <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: magazineDoc.id,
                        trans:trans
                      }}">
              <div
                  style="cursor: pointer;width: 250px;margin-top: 23px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                {{ magazineDoc.Title }}
              </div>
            </router-link>
            <!--            <div
                            style="width: 250px;margin-top: 14px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 500;color: #999999;">
                          2022年02期
                        </div>-->
            <div
                style="width: 250px;margin-top: 43px;font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              相关论文
            </div>
            <div
                style="width: 250px;margin-top: 10px;font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              <div style="width: 93px;height: 3px;background: #999999;"></div>
              <div style="width: 250px;height: 1px;background: #CCCCCC;"></div>
            </div>
            <div v-show="data&&data.length>0" v-for="(item,index) in data">
              <div style="width: 250px;">
                <router-link target="_blank" :to="{name:'Document',query: {
                              collection: 'Periodical',
                              id: doc.id
                            }}">
                  <div
                      style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                      width: 250px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 15px 0">
                    <span v-html="item.Title"></span>
                  </div>
                  <div style="width: 250px;height: 1px;background: #CCCCCC;"></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="trans" style="width:100%;display: flex;flex-flow: column;justify-content: center;align-items: center;">
      <div style="display: flex; align-items: flex-start;justify-content: space-between;width: 1200px;">
        <div style="margin-top: 52px;font-size: 24px;font-family: Source Han Sans CN;font-weight: bold;color: #333333;
              display: flex;align-items: center;"
             v-if="doc.Title_Translated">
          <span v-html="doc.Title_Translated"></span>
          <span v-show="doc.Translated_Status&&doc.Translated_Status==='ok'"
                style="display: flex;padding-left: 10px;">
            <img src="../assets/yi.png" alt=""></span>
        </div>
      </div>
      <div
          style="width: 1200px;margin-top: 15px;font-size: 14px;font-family: Source Han Sans CN;font-weight: bold;color: #666666;"
          v-if="doc.PeriodicalTitle_Translated">
        <span v-html="doc.PeriodicalTitle_Translated"></span>
        <span v-if="doc.PublishDate">（{{ doc.PublishDate }}）</span>
      </div>
      <div
          style="width: 1200px;cursor: pointer;display: flex;align-items: end;height: 50px;font-size: 14px;
          font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
        <div style="width: 170px;display: flex;justify-content: space-between;">
          <div style="display: flex;align-items: center;justify-content: space-between;width: 70px;">
            <img src="../assets/share-active.png" alt=""/>
            <el-popover
                placement="bottom"
                width="140"
                trigger="hover">
              <div
                  style="height: 140px;display: flex;flex-flow: column;justify-content: space-between;">
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('wechat',doc.Title_Translated,doc.Abstract_Translated,doc.id)">
                  <img src="../assets/wechar.png" width="24" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    微信
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('sina',doc.Title_Translated,doc.Abstract_Translated,doc.id)">
                  <img src="../assets/webo.png" width="23" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    微博
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('qq',doc.Title_Translated,doc.Abstract_Translated,doc.id)">
                  <img src="../assets/qq.png" width="20" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    QQ
                  </div>
                </div>
                <div style="cursor: pointer;display: flex;align-items: center;"
                     @click="shareTo('qzone',doc.Title_Translated,doc.Abstract_Translated,doc.id)">
                  <img src="../assets/qzone.png" width="24" alt="" style="margin-left: 3px"/>
                  <div
                      style="margin-left:16px;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                    QQ空间
                  </div>
                </div>

              </div>
              <el-button type="text" slot="reference">
                                <span
                                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                                转发
                              </span>
              </el-button>
            </el-popover>
          </div>
          <div @click="collDoc(doc)"
               style="display: flex;align-items: center;justify-content: space-between;width: 70px;">
            <img v-show="!hasColl" src="../assets/collection-active.png" alt=""/>
            <img v-show="hasColl" src="../assets/collection-ok.png" alt=""/>
            <el-button type="text" :loading="collDocLoading">
                <span
                    style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                  收藏
                </span>
            </el-button>
          </div>
        </div>
      </div>
      <el-menu style="width: 1200px;" v-if="doc.Abstract_Translated" class="el-menu-vertical-demo"
               :default-openeds="['1']">
        <el-submenu index="1">
          <template slot="title">
            <span
                style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;">摘要</span>
          </template>
          <span
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;line-height: 30px;"
              v-html="doc.Abstract_Translated"></span>
        </el-submenu>
      </el-menu>
      <div class="info">
        <div v-if="doc.DOI"><span>DOI:</span>{{ doc.DOI }}</div>
        <div v-if="doc.Creator_Translated">
          <span>作者:</span>
          <span v-for="(item,index) in doc.Creator_Translated.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("作者:\""+item+"\"")'>{{ item }}</span>
            {{ doc.Creator_Translated.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.ForeignKeywords_Translated">
          <span>关键词:</span>
          <span v-for="(item,index) in doc.ForeignKeywords_Translated.split(';')||[]">
            <span style="cursor: pointer" @click='toSearchWord("关键词:\""+item+"\"")'>{{ item }}</span>
            {{ doc.ForeignKeywords_Translated.split(';').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.OriginalOrganization_Translated">
          <span>作者单位:</span>
          <span v-for="(item,index) in doc.OriginalOrganization_Translated.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("作者单位:\""+item+"\"")'>{{ item }}</span>
            {{ doc.OriginalOrganization_Translated.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div v-if="doc.PublishYear"><span>年、卷（期）:</span>{{ doc.PublishYear }}，{{
            doc.Volum
          }}{{ doc.Issue ? "（" + doc.Issue + "）" : '' }}
        </div>
        <div v-if="doc.OriginalClassCode"><span>分类号:</span>
          <span v-for="(item,index) in doc.OriginalClassCode.split('%%')||[]">
            <span style="cursor: pointer" @click='toSearchWord("分类号:*"+item.trim().substr(1, 4)+"*")'>{{
                classCodeZh[item.trim().substr(1, 4)] || item
              }}</span>
            {{ doc.OriginalClassCode.split('%%').length - 1 !== index ? '  ' : '' }}</span>
        </div>
        <div class="item" v-show="doc.HasFulltext&&doc.HasFulltext.toLowerCase()==='y'">
          <div class="inner">
            <div @click="readView(doc)" class="common-back">
              <img src="../assets/read-detail.png" alt=""/>
              在线阅读
            </div>
            <el-dropdown v-show="hasRemark" @command="handleOrgCommand($event,doc)">
              <div class="common-back">
                <img src="../assets/down-detail.png" alt=""/>
                下载
              </div>
              <el-dropdown-menu slot="dropdown"
                                style="width: 104px;background: #FFFFFF;box-shadow: 0px 0px 13px 0px rgba(3, 27, 63, 0.25);">
                <el-dropdown-item
                    style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                    command="org">
                  <el-radio v-model="clas" label="org">原文</el-radio>
                </el-dropdown-item>
                <el-dropdown-item
                    style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;"
                    command="tran">
                  <el-radio v-model="clas" label="tran">译文</el-radio>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popover v-show="!hasRemark"
                        placement="bottom"
                        width="239"
                        trigger="hover">
              <div
                  style="width: 100%;display:flex;justify-content: center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
                请阅读详情并 <a href="#miao"><span style="cursor: pointer;color: #A2CCCA;padding: 0 10px">进行评价</span></a> 即可下载
              </div>
              <div class="common-back-none" slot="reference">
                <img src="../assets/down-detail.png" alt=""/>
                <span>下载</span>
              </div>
            </el-popover>
            <div @click="transDoc(doc)" v-show="!doc.Translated_Status||doc.Translated_Status!=='ok'"
                 class="common-back">
              <img src="../assets/trans-detail.png" alt=""/>
              翻译
            </div>
          </div>
        </div>
      </div>
      <div class="send">
        <div style="width: 1200px;display: flex;justify-content: space-between;">
          <div style="width: 760px;margin-top: 25px;">
            <span v-if="jsonRemarkType!==undefined" v-for="(item,index) in jsonRemarkType||[]" :key="index+'_remark'">
              <div v-show="item.remark" style="display: flex;
    align-items: center;
    justify-content: center;width: 100%;font-size: 26px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              <div style="width: 50px;
height: 1px;
background: #DDDDDD;">
              </div>
              <div style="display: flex;
    align-items: center;
    justify-content: center;font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #999999;line-height: 56px;padding: 0 8px">{{ item.remark }}</div>
              <div style="width: 50px;
height: 1px;
background: #DDDDDD;">
              </div>
            </div>
            <div style="width: 100%;margin-top: 26px;display: flex;flex-wrap: wrap;">
              <div v-for="(childItem,childIndex) in item.childRemarkTypes||[]" :key="childIndex+'_child_remark'"
                   @click="checkRemark(childItem)"
                   :style="`background-image: linear-gradient(to right ,#EEEEEE ${childItem.count>0?valCount(childItem.count):100}%, #F8F8F9 0%)`"
                   style="justify-content: space-between;width: 44%;margin-bottom:10px;cursor: pointer;padding: 0px 15px;height: 40px;border-radius: 5px;font-size: 16px;margin-right: 10px;
                              font-family: Source Han Sans CN;font-weight: 400;color: #666666;display: flex;align-items: center;">
                {{ childItem.name }}<span style="margin-right: 5px"></span><span
                  v-show="childItem.count>0">{{ formatCount(childItem.count) }}</span>
              </div>
            </div>
            <div
                style="margin-top: 24px;width: 720px;height: 1px;"></div>
            </span>
          </div>
          <div v-show="magazineDoc&&magazineDoc.Title_Translated"
               style="width: 340px;margin-top: 22px;background: #F5F5F5;display: flex;align-items: center;
              flex-flow: column;">
            <img :src="'https://file.wesitrans.com/wesitrans/template/perio/img/'+ magazineDoc.id+'.jpg'"
                 @error="e=>{e.target.src='https://file.wesitrans.com/wesitrans/template/perio/img/default_perio_img.png'}"
                 width="252" height="331"
                 style="margin-top: 27px;box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);border-radius: 5px;" alt=""/>
            <router-link target="_blank" :to="{name:'MagazineInfo',query: {
                        collection: 'Magazine',
                        id: magazineDoc.id,
                        trans:trans
                      }}">
              <div
                  style="cursor: pointer;width: 250px;margin-top: 23px;font-size: 20px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
                {{ magazineDoc.Title_Translated }}
              </div>
            </router-link>
            <!--            <div
                            style="width: 250px;margin-top: 14px;font-size: 14px;font-family: Source Han Sans CN;font-weight: 500;color: #999999;">
                          2022年02期
                        </div>-->
            <div
                style="width: 250px;margin-top: 43px;font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              相关论文
            </div>
            <div
                style="width: 250px;margin-top: 10px;font-size: 24px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
              <div style="width: 93px;height: 3px;background: #999999;"></div>
              <div style="width: 250px;height: 1px;background: #CCCCCC;"></div>
            </div>
            <div v-show="data&&data.length>0" v-for="(item,index) in data">
              <div style="width: 250px;">
                <router-link target="_blank" :to="{name:'Document',query: {
                              collection: 'Periodical',
                              id: doc.id
                            }}">
                  <div
                      style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #231815;
                      width: 250px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;padding: 15px 0">
                    <span v-html="item.Title_Translated"></span>
                  </div>
                  <div style="width: 250px;height: 1px;background: #CCCCCC;"></div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="downLoadList&&downLoadList.length>0" class="downs" style="
          height: 50px;
          z-index: 100;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 10px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <el-progress v-for="(item,index) in  downLoadList" :key="item.id" style="padding-left: 10px;padding-right: 10px"
                     :stroke-width="2" :color="'rgb(6, 63, 149)'"
                     status="success" :width="35" type="circle"
                     :percentage="50">
        </el-progress>
      </div>
    </div>
    <div v-if="isOk" style="
          height: 50px;
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 75px;
          right: 0;
          border-radius: 6px;">
      <div style="height: 50px;width: 100%;display: flex;align-items: center;justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;">
        <span>翻译完成<span style="cursor: pointer;color: rgba(95, 138, 202, 1);margin-right: 22px"
                        @click="toList">去查看</span></span>
        <img src="../assets/OK.png" alt=""/>
      </div>
    </div>
    <div v-if="isShowTransDoc" style="
          z-index: 100;width: 330px;
          background: #FFFFFF;
          box-shadow: 0px 0px 10px 0px rgba(3, 27, 63, 0.25);
          position: fixed;
          left: auto;
          bottom: 140px;
          right: 0;
          border-radius: 6px;">
      <div class="isShowTransDoc" v-for="(item,index) in JSON.parse(isShowTransDoc)">
        <div
            style="color: #333333 !important;padding: 14px;">
          <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
            <div style="width: 200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                 v-html="item.Title"></div>
            <img style="cursor: pointer" src="../assets/close.png" alt="" @click="closes(item.id)"/>
          </div>
          <el-progress :percentage="item.number?item.number:0" :format="format">
          </el-progress>
        </div>
      </div>
    </div>
    <el-dialog
        :top="'40vh'"
        :show-close="false"
        :visible.sync="setMailVisible"
        width="280px"
        center>
        <span slot="title"
              style="font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #999999;">
           您暂未设置邮箱，请填写邮箱
        </span>
      <div style="width: 100%;display: flex;justify-content: center">
        <div
            style="width: 95px;height: 30px;background: #333333;border-radius: 5px;display: flex;align-items: center;justify-content: center;">
            <span style="cursor: pointer;font-size: 14px;font-family: Microsoft YaHei;font-weight: 400;color: #FFFFFF;"
                  @click="toSetMail">
              填写邮箱
            </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        title="分享到微信朋友圈"
        :visible.sync="qrcodeCenterDialogVisible"
        width="358px">
      <div
          style="margin-top: -25px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #e8eaec;">
        <vue-qr :size="220" :text="commonUrl"></vue-qr>
      </div>
      <div style="text-align: left;border-top: 1px solid #e8eaec;padding-top: 16px">
        打开微信，使用 “扫一扫”即可将网页分享到我的朋友圈
      </div>
    </el-dialog>
    <el-dialog
        title="分享到QQ"
        :visible.sync="qqQrcodeCenterDialogVisible"
        width="550px">
      <div
          style="width: 500px;height:550px;margin-top: -25px;display: flex;align-items: center;justify-content: center;border-top: 1px solid #e8eaec;">
        <iframe scrolling="no" id="iframe" :src="iframeSrc" style="height:500px;" width="100%" frameborder="0"></iframe>
      </div>
    </el-dialog>
    <el-dialog
        title="提示"
        :visible.sync="downCenterDialogVisible"
        width="486px"
        :show-close="false"
        :before-close="clearClas"
        center>
      <div slot="title" style="font-size: 18px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;">
        请选择下载格式
      </div>
      <div style="width: 100%;display:flex;justify-content: center;align-items: center">
        <div class="option" :style="clas!=='tran'?'width: 50%;':'width: 100%;'"
             style="cursor: pointer;margin: -30px 35px;display: flex;align-items: center;justify-content: space-between;">
          <div :class="comment==='pdf'?'select':''" @click="checkComment('pdf')">PDF
          </div>
          <div :class="comment==='docx'?'select':''" @click="checkComment('docx')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">WORD
          </div>
          <div v-show="clas==='tran'" :class="comment==='commentsone'?'select':''" @click="checkComment('commentsone')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">原文批注
          </div>
          <div v-show="clas==='tran'" :class="comment==='commentstwo'?'select':''" @click="checkComment('commentstwo')"
               style="font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;display:flex;
          justify-content: center;align-items: center;
          color: #666666;width: 81px;height: 30px;background: #F5F5F5;border-radius: 5px;">译文批注
          </div>
        </div>
      </div>
      <div style="width: 100%;display: flex;align-items: center;justify-content: center" slot="footer">
        <div style="cursor: pointer;font-size: 16px;font-family: Source Han Sans CN;font-weight: 400;color: #FFFFFF;margin-bottom: 6px;
   width: 100px;height: 32px;background: linear-gradient(-50deg, #231815, #453835);opacity: 0.96;border-radius: 5px;
display: flex;align-items: center;justify-content: center" @click="confirmDown">
          确 认
        </div>
      </div>
    </el-dialog>
    <div v-show="showAdd" style="width: 148px;    position: fixed;    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;bottom: 0;left: 0;right: 0;margin: auto;
height: 47px;
background: #FFFFFF;
box-shadow: 0px 0px 8px 0px rgba(3,27,63,0.44);font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #231815;">
      标记成功<span style="color: #E83428">+1</span>
    </div>
  </div>
</template>

<script>
import {
  searchServiceClient,
  documentRequest,
  queryRequest,
  downloadRequest,
  transDocRequest,
  checkStatusRequest,
  collRequest,
  getRemarkTypeRequest,
  checkRemarkRequest
} from '@/tools/search-api'
import classCode from "../../classCode.json"
import classCodeZh from "../../classCodeZh.json"
import jwt from "jwt-decode";
import vueQr from "vue-qr"

export default {
  name: "Document",
  components: {
    vueQr
  },
  data() {
    return {
      downCenterDialogVisible: false,
      iframeSrc: '',
      commonUrl: '',
      qqQrcodeCenterDialogVisible: false,
      qrcodeCenterDialogVisible: false,
      setMailVisible: false,
      classCode: classCode,
      classCodeZh: classCodeZh,
      id: '',
      collection: '',
      doc: {},
      magazineDoc: {},
      hasColl: false,
      data: [],
      downLoadList: [],
      clas: '',
      comment: '',
      downLoadItem: undefined,
      isShowTransDoc: undefined,
      transTimer: undefined,
      isOk: false,
      oldToken: '',
      checkLogin: undefined,
      collDocLoading: false,
      jsonRemarkType: undefined,
      hasRemark: false,
      showAdd:false,
    }
  },
  created() {
    this.id = this.$route.query.id
    this.collection = this.$route.query.collection
    this.trans = JSON.parse(this.$route.query.trans || true)
    if (this.id && this.collection) {
      this.document()
      this.getRemarkType()
    }
    let isShowTransDoc = localStorage.getItem("isShowTransDoc")
    if (isShowTransDoc) {
      this.isShowTransDoc = isShowTransDoc
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    }
  },
  computed: {
    trans() {
      return this.$store.state.trans
    },
  },
  methods: {
    checkRemark(item) {
      item.check = !item.check;
      if (item.check) {
        item.count = item.count + 1
      } else {
        item.count = item.count - 1
      }
      this.hasRemark = false
      this.jsonRemarkType.forEach((item, index) => {
        item.childRemarkTypes.forEach((childItem, childIndex) => {
          if (childItem.check) {
            this.hasRemark = childItem.check
          }
        })
      })
      let request = checkRemarkRequest
      request.setDocId(this.doc.id)
      request.setPeriodicalId(this.doc.PeriodicalId)
      request.setTypeId(item.id)
      request.setParentTypeId(item.parentId)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.checkRemark(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus()) {
          if (item.check) {
            this.showAdd = true
            setTimeout(()=>this.showAdd=false,3000)
          }
          return
        }
        item.check = !item.check;
        if (item.check) {
          item.count = item.count + 1
        } else {
          item.count = item.count - 1
        }
        this.hasRemark = false
        this.jsonRemarkType.forEach((item, index) => {
          item.childRemarkTypes.forEach((childItem, childIndex) => {
            if (childItem.check) {
              this.hasRemark = childItem.check
            }
          })
        })
        if (res.getMessage() === "login") {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getMessage() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        this.$message.warning(res.getMessage())
      }).catch(err => {
        item.check = !item.check;
        if (item.check) {
          item.count = item.count + 1
        } else {
          item.count = item.count - 1
        }
        this.hasRemark = false
        this.jsonRemarkType.forEach((item, index) => {
          item.childRemarkTypes.forEach((childItem, childIndex) => {
            if (childItem.check) {
              this.hasRemark = childItem.check
            }
          })
        })
        console.log(err)
      })
    },
    valCount(val) {
      let fr = 1000;
      let num = 3;
      while (val / fr >= 1) {
        fr *= 10;
        num += 1;
      }
      if (num <= 4) { // 千
        return parseInt(val / 1000);
      } else if (num <= 8) { // 万
        const str = parseInt(num - 4) / 3 > 1 ? '千万+' : '万+';
        const fm = '万+' === str ? 10000 : 10000000;
        return parseInt(val / fm);
      } else if (num <= 16) {// 亿
        let str = (num - 8) / 3 > 1 ? '千亿+' : '亿+';
        str = (num - 8) / 4 > 1 ? '万亿+' : str;
        str = (num - 8) / 7 > 1 ? '千万亿+' : str;
        let fm = 1;
        if ('亿+' === str) {
          fm = 100000000;
        } else if ('千亿+' === str) {
          fm = 100000000000;
        } else if ('万亿+' === str) {
          fm = 1000000000000;
        } else if ('千万亿+' === str) {
          fm = 1000000000000000;
        }
        return parseInt(val / fm);
      }
      if (val < 1000) {
        return parseInt(val / 100);
      }
    },
    formatCount(val) {
      let nVal = ['', '', ''];
      let fr = 1000;
      let num = 3;
      while (val / fr >= 1) {
        fr *= 10;
        num += 1;
      }
      if (num <= 4) { // 千
        nVal[1] = '千+';
        nVal[0] = parseInt(val / 1000) + '';
      } else if (num <= 8) { // 万
        const str = parseInt(num - 4) / 3 > 1 ? '千万+' : '万+';
        const fm = '万+' === str ? 10000 : 10000000;
        nVal[1] = str;
        nVal[0] = (val / fm) + '';
      } else if (num <= 16) {// 亿
        let str = (num - 8) / 3 > 1 ? '千亿+' : '亿+';
        str = (num - 8) / 4 > 1 ? '万亿+' : str;
        str = (num - 8) / 7 > 1 ? '千万亿+' : str;
        let fm = 1;
        if ('亿+' === str) {
          fm = 100000000;
        } else if ('千亿+' === str) {
          fm = 100000000000;
        } else if ('万亿+' === str) {
          fm = 1000000000000;
        } else if ('千万亿+' === str) {
          fm = 1000000000000000;
        }
        nVal[1] = str;
        nVal[0] = parseInt(val / fm) + '';
      }
      if (val < 1000) {
        nVal[1] = '';
        nVal[0] = val + '';
      }
      return nVal.join('');
    },
    getRemarkType() {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let request = getRemarkTypeRequest
      request.setDocId(this.id)
      searchServiceClient.getRemarkType(request, {
        ['token']: token
      }).then(res => {
        console.log(JSON.parse(res.getJsonRemarkType()))
        this.jsonRemarkType = JSON.parse(res.getJsonRemarkType())
        this.jsonRemarkType.forEach((item, index) => {
          item.childRemarkTypes.forEach((childItem, childIndex) => {
            if (childItem.check) {
              this.hasRemark = childItem.check
            }
          })
        })
      }).catch(err => {
        console.log(err)
      })
    },
    clearClas() {
      this.clas = ''
      this.comment = ''
      this.downCenterDialogVisible = false
    },
    shareTo(stype, ftit, summary, id) {
      ftit = ftit.replaceAll("<span style='color:rgba(245, 108, 108, 1)'>", "")
          .replaceAll("</span>", "")
      summary = summary.replaceAll("<span style='color:rgba(245, 108, 108, 1)'>", "")
          .replaceAll("</span>", "")
      let href = encodeURIComponent("https://hy.wesitrans.com" + this.$router.resolve({
        name: 'Document',
        query: {
          collection: 'Periodical',
          id: id,
          trans: true
        }
      }).href);
      //qq空间接口的传参
      if (stype === 'qzone') {
        window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
            href + '?sharesource=qzone&title=' + ftit + '&summary=' + summary);
      }
      //新浪微博接口的传参
      if (stype === 'sina') {
        window.open('https://service.weibo.com/share/share.php?url=' + href +
            '?sharesource=weibo&title=' + ftit + '&appkey=4030027943');
      }
      //qq好友接口的传参
      if (stype === 'qq') {
        this.iframeSrc = 'https://connect.qq.com/widget/shareqq/index.html?url=' + href +
            '?sharesource=qzone&title=' + ftit + '&summary=' + summary +
            '&desc=浩语，突破翻译，发现文章';
        this.qqQrcodeCenterDialogVisible = true
      }
      //生成二维码给微信扫描分享，php生成，也能够用jquery.qrcode.js插件实现二维码生成
      if (stype === 'wechat') {
        this.commonUrl = decodeURIComponent(href)
        this.qrcodeCenterDialogVisible = true
      }
    },
    toSetMail() {
      window.open("https://user.wesitrans.com/account", '_blank')
    },
    checkLogins() {
      let token = this.$cookies.get("token")
      if (token && this.oldToken !== token) {
        clearInterval(this.checkLogin)
        this.oldToken = token
        localStorage.setItem('token', token)
        let auth = jwt(token)
        localStorage.setItem('auth-context', auth.userInfoList)
        window.location.reload()
      }
    },
    format(percentage) {
      return percentage === 100 ? '完成' : `${percentage}%`;
    },
    closes(id) {
      let isShowTransDoc = JSON.parse(this.isShowTransDoc)
      let docs = []
      isShowTransDoc.forEach((item, index) => {
        if (item.id === id) {
          return
        }
        docs.push(item)
      })
      if (docs.length === 0) {
        this.isShowTransDoc = undefined
        localStorage.removeItem("isShowTransDoc")
        return
      }
      this.isShowTransDoc = JSON.stringify(docs)
      localStorage.setItem("isShowTransDoc", this.isShowTransDoc)
    },
    toList() {
      this.isOk = false;
      this.$router.push('/list')
    },
    checkComment(command) {
      this.radio = command !== 'pdf' && command !== 'docx' ? 'docx' : command
      this.comment = command
    },
    transDoc(item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let isShowTransDoc = localStorage.getItem("isShowTransDoc")
      if (isShowTransDoc) {
        let docs = JSON.parse(isShowTransDoc)
        for (let i = 0; i < docs.length; i++) {
          if (!docs[i].id) {
            localStorage.removeItem("isShowTransDoc")
          } else {
            if (docs[i].id === item.id) {
              this.$message.warning("正在翻译啦，请不要着急！")
              return;
            }
          }
        }
        let request = transDocRequest
        request.setId(item.id)
        searchServiceClient.transDoc(request, {
          ['token']: token
        }).then(res => {
          if (res.getStatus() === "login") {
            this.showIframe('/loginTwo?code=haoyi')
            return
          }
          if (res.getStatus() === 'login-person') {
            this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
            this.showIframe('/loginTwo?code=haoyi')
            return;
          }
          if (res.getStatus() === "remote") {
            this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
            return
          }
          if (res.getStatus() === "set_mail") {
            this.setMailVisible = true
            return
          }
          if (res.getStatus() === "fail") {
            this.$message.error("翻译失败，请稍后再试！")
            return
          }
          if (res.getStatus() === "time") {
            this.$message.error("您的机构使用时间已截至,请联系销售人员！")
            return
          }
          if (res.getStatus() === "success") {
            docs.push({
              id: item.id,
              Title: item.Title
            })
            localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
            this.isShowTransDoc = JSON.stringify(docs)
            return
          }
          this.$message.error(res.getStatus())
        }).catch(err => {
          this.$message.error("翻译异常，请稍后再试！")
        })
        return
      }
      let request = transDocRequest
      request.setId(item.id)
      searchServiceClient.transDoc(request, {
        ['token']: token
      }).then(res => {
        if (res.getStatus() === "login") {
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getStatus() === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() === "remote") {
          this.$message.error("当前账户不具备翻译权限,请联系销售人员！")
          return
        }
        if (res.getStatus() === "set_mail") {
          this.setMailVisible = true
          return
        }
        if (res.getStatus() === "fail") {
          this.$message.error("翻译失败，请稍后再试！")
          return
        }
        if (res.getStatus() === "time") {
          this.$message.error("您的机构使用时间已截至,请联系销售人员！")
          return
        }
        if (res.getStatus() === "success") {
          localStorage.setItem("isShowTransDoc", JSON.stringify([{id: item.id, Title: item.Title}]))
          this.isShowTransDoc = JSON.stringify([{id: item.id, Title: item.Title}])
          return
        }
        this.$message.error(res.getStatus())
      }).catch(err => {
        this.$message.error("翻译异常，请稍后再试！")
      })
      clearInterval(this.transTimer)
      this.transTimer = setInterval(() => this.checkStatus(), 3000);
    },
    checkStatus() {
      if (this.isShowTransDoc) {
        let docs = JSON.parse(this.isShowTransDoc)
        let isOk = true
        for (let i = 0; i < docs.length; i++) {
          if (docs[i].number === 100) {
            continue
          }
          isOk = false
          let Range = 5 - 1;
          let Rand = Math.random();
          let num = 1 + Math.round(Rand * Range) + parseInt(docs[i].number || 0)
          docs[i].number = num > 99 ? 99 : num
          let request = checkStatusRequest
          request.setDocId(docs[i].id)
          let token = this.$cookies.get('token')
          if (!token) {
            token = localStorage.getItem('token');
          }
          searchServiceClient.checkStatus(request, {
            ['token']: token
          }).then(res => {
            if (res.getStatus()) {
              docs[i].number = 100;
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            } else {
              localStorage.setItem("isShowTransDoc", JSON.stringify(docs))
              this.isShowTransDoc = JSON.stringify(docs)
            }
          }).catch(err => {
            console.log(err)
          })
        }
        if (isOk) {
          clearInterval(this.transTimer)
          localStorage.removeItem("isShowTransDoc")
          this.isShowTransDoc = undefined;
          this.isOk = isOk
        }
      } else {
        clearInterval(this.transTimer)
      }
    },
    showIframe(url) {
      this.oldToken = this.$cookies.get("token")
      const width = $(window).width();
      if (width < 1100) {
        window.layer.open({
          skin: 'login-demo-class',
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      } else {
        window.layer.open({
          area: ['948px', '501px'],
          type: 2,
          title: false,
          shadeClose: true,
          anim: -1,
          content: ['https://wesitrans.com' + url, 'no'],
        });
      }
      this.checkLogin = setInterval(this.checkLogins, 1000);
    },
    readView(item) {
      this.$router.push({
        name: "ReadView",
        query: {
          collection: this.collection,
          id: item.id
        }
      })
    },
    handleOrgCommand(command, item) {
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        debugger
        if (this.clas !== '') {
          this.showIframe('/loginTwo?code=haoyi')
        }
        this.clas = ''
        return
      }
      if (this.jsonRemarkType === undefined || !this.hasRemark) {
        return;
      }
      this.downLoadItem = item
      this.downCenterDialogVisible = true
    },
    confirmDown() {
      this.downCenterDialogVisible = false
      this.downLoadList.push(this.downLoadItem)
      this.downloadDoc()
      this.clas = ''
      this.comment = ''
    },
    downloadDoc() {
      debugger
      let request = downloadRequest;
      request.setId(this.downLoadItem.id);
      request.setType(this.radio)
      request.setClasses(this.clas)
      request.setComment(this.comment)
      request.setName(this.downLoadItem.Title)
      const that = this;
      const TOKEN_KEY = 'token';
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      let stream = searchServiceClient.download(request, {
        [TOKEN_KEY]: token
      });
      let flag = 0;
      let content = "";
      let title = "";
      let type = "";
      let message = '';
      stream.on('data', (res) => {
        message = res.getMessage()
        if (message === 'login') {
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (message === 'login-person') {
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getStatus() && flag === 0) {
          title = res.getTitle();
          type = res.getType();
        }
        if (!res.getStatus() && flag === 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: res.getMessage()
          });
          that.centerDialogVisible = false;
          return;
        }
        flag++;
        content += res.getContent_asB64();
      });
      stream.on('end', (end) => {
        if (message === 'login') {
          that.downLoadList = []
          return
        }
        if (message === 'login-person') {
          that.downLoadList = []
          return;
        }
        let aLink = document.createElement('a');
        let blob = that.base64ToBlob(content, type);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true);
        aLink.download = title;
        aLink.href = window.URL.createObjectURL(blob)
        aLink.click();
        that.centerDialogVisible = false;
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
      });
      stream.on('error', (err) => {
        let downLoadList = []
        that.downLoadList.forEach(item => {
          if (item.id === request.getId()) {
            return
          }
          downLoadList.push(item)
        })
        that.downLoadList = downLoadList
        that.centerDialogVisible = false;
        if (err && err.code !== 0) {
          that.$message({
            center: true,
            type: 'error',
            showClose: true,
            duration: 3000,
            message: '下载文档失败，请稍后再试！'
          })
        }
      });
    },
    base64ToBlob(code, type) {
      let bstr = atob(code)
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: type});
    },
    transTo() {
      this.trans = !this.trans
    },
    document() {
      let request = documentRequest;
      request.setId(this.id)
      request.setCollection(this.collection)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.document(request, {
        ['token']: token
      }).then(res => {
        this.hasColl = res.getHascoll()
        this.doc = JSON.parse(res.getDocument())[0]
        this.toSearch()
        this.manageDoc()
      }).catch(err => {
        console.log(err)
      })
    },
    manageDoc() {
      let request = documentRequest;
      request.setId(this.doc.PeriodicalId)
      request.setCollection("Magazine")
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.document(request, {
        ['token']: token
      }).then(res => {
        this.magazineDoc = JSON.parse(res.getDocument())[0]
      }).catch(err => {
        console.log(err)
      })
    },
    toSearchWord(word) {
      this.$router.push({
        name: 'Periodical',
        query: {
          collection: 'Periodical',
          select: '所有领域',
          word: word,
          currentPage: 1,
          pageSize: 10,
          filter: [],
          trans: true
        }
      })
    },
    collDoc(item) {
      this.collDocLoading = true
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      if (!token) {
        this.collDocLoading = false
        this.showIframe('/loginTwo?code=haoyi')
        return
      }
      let href = "https://hy.wesitrans.com" + this.$router.resolve({
        name: 'Document',
        query: {
          collection: 'Periodical',
          id: item.id,
          trans: true
        }
      }).href;
      let request = collRequest
      request.setDocId(item.id)
      request.setCollection(this.collection)
      request.setUrl(document.URL)
      searchServiceClient.coll(request, {
        ['token']: token
      }).then(res => {
        if (res.getMessage() === "login") {
          this.collDocLoading = false
          this.showIframe('/loginTwo?code=haoyi')
          return
        }
        if (res.getMessage() === 'login-person') {
          this.collDocLoading = false
          this.$message.warning("暂时不支持个人用户操作，请登录机构子账号！")
          this.showIframe('/loginTwo?code=haoyi')
          return;
        }
        if (res.getMessage() === "remote") {
          this.collDocLoading = false
          this.$message.error("当前账户不具备收藏权限,请联系销售人员！")
          return
        }
        if (!res.getStatus()) {
          this.collDocLoading = false
          this.$message.error(res.getMessage())
          return
        }
        this.collDocLoading = false
        this.$message.success(res.getMessage())
        this.hasColl = !this.hasColl
      }).catch(err => {
        this.collDocLoading = false
        this.$message.error("收藏异常，请稍后再试！")
      })
    },
    toSearch() {
      if (!this.doc.ForeignKeywords) {
        return
      }
      let keys = this.doc.ForeignKeywords.split(";")
      let word = ''
      keys.forEach((item, index) => {
        word += '关键词:"' + item + '"'
        word += ' OR '
      })
      let request = queryRequest
      request.setCurrentPage(2)
      request.setPageSize(5)
      request.setFilterQueryList([])
      request.setSortField('')
      request.setDefaultField('所有领域')
      request.setQueryWord(word)
      request.setCollection(this.collection)
      let token = this.$cookies.get('token')
      if (!token) {
        token = localStorage.getItem('token');
      }
      searchServiceClient.query(request, {
        ['token']: token
      }).then(res => {
        let document = res.getDocument()
        if (!document) {
          this.$message.warning("查询为空")
          return
        }
        let parse = JSON.parse(document);
        this.data = parse.documents || []
      }).catch(err => {
        console.log(err)
        this.$message.warning("查询失败")
      })
    }
  },
  destroyed() {
    clearInterval(this.transTimer)
    clearInterval(this.checkLogin)
  }
}
</script>
<style lang="less">
.option {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.option div {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  width: 81px;
  height: 30px;
  background: #F5F5F5;
  border-radius: 5px;
}

.option div.select {
  background: #F5F5F5;
  border: 1px solid #E83428;
  position: relative;
}

.option div.select::after {
  content: "";
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-bottom: 24px solid #E83428;
  border-left: 23px solid transparent;
}

.option div.select::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 4px;
  background: transparent;
  bottom: 6px;
  right: 0px;
  border: 2px solid white;
  border-top: none;
  border-right: none;
  transform: rotate(-55deg);
  z-index: 9;
}
.el-dropdown-menu{
  .el-dropdown-menu__item{
    .el-radio {
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: center;
    }

    .el-radio__label {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .el-radio__input.is-checked + .el-radio__label {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #666666;
    }

    .el-radio__input.is-checked .el-radio__inner::after {
      transform: rotate(45deg) scaleY(0);
    }

    .el-radio__input.is-checked .el-radio__inner {
      border-color: #E83428;
      background: #E83428;
      width: 9px;
      height: 9px;
      background: #E83428;
      border-radius: 50%;
    }

    .el-radio__inner {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 0px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-radio__input {
      width: 19px;
      height: 19px;
      background: #F5F5F5;
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .el-radio__inner::after {
      background: #F5F5F5;
    }
  }
}

.el-button--text {
  color: rgb(153, 153, 153);
}

.el-select-dropdown {
  .el-select-dropdown__item {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }

  .el-select-dropdown__item.selected {
    color: #333333;
    font-weight: 700;
  }
}
</style>
<style lang="less" scoped>
.check-remark {
  background: #E83428 !important;
  font-size: 16px !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
}

/deep/ .downs {
  .el-icon-check:before {
    content: url('');
    display: block;
    width: 14px;
    height: 14px;
    background: url('../assets/downico.png');
    background-size: cover;
  }
}

/deep/ .isShowTransDoc {
  .el-progress-bar {
    padding-right: 50px;
    width: 117%;
    margin-right: -54px;
    box-sizing: border-box;
  }

  .el-progress__text {
    margin-top: -35px;
    margin-left: -55px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #5F8ACA;
  }
}

/deep/ .el-menu-vertical-demo {
  border: 0;

  .el-menu {
    border: 0;
    background-color: #F5F5F5;
  }

  .el-submenu {
    background-color: #F5F5F5;
  }

  .el-submenu__title:hover {
    background: #F5F5F5;
  }

  .el-submenu__title {
    padding-left: 0 !important;
    width: 100px;
    background-color: #F5F5F5;

    .el-icon-arrow-down:before {
      content: url("../assets/sanjiao.png");
    }

  }
}

.send {
  width: 100%;
  background: #FFFFFF;
  height: 1200px;
  display: flex;
  justify-content: center;
}

.info {
  width: 1200px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  margin-top: 24px;
  margin-bottom: 26px;

  span {
    margin-right: 10px;
  }

  .item {
    margin-top: 26px;
    width: 1200px;
    height: 45px;
    background: #F5F5F5;

    .inner {
      cursor: pointer;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
      display: flex;

      .common-back {
        background: url("../assets/common-back.png") no-repeat;
        background-size: 123px 40px;
        width: 123px;
        height: 40px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      .common-back-none {
        background: url("../assets/common-back-none.png") no-repeat;
        background-size: 123px 40px;
        width: 123px;
        height: 40px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.xiangguan {
  margin-top: 30px;
  margin-bottom: 100px;

  .title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .lunwen-list {
    cursor: pointer;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    height: 40px;
    border-bottom: 1px dashed #999999;
    display: flex;
    align-items: center;
    width: 1200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>